import React, { Component, createRef } from 'react';
import Field from './Field';
import axios from 'axios';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getCountries } from '../services/getCountries';
import { getBatches } from '../services/getBatches';
import { api } from '../Settings';
import Loader from './../components/Loader';
import { BiLoaderAlt } from 'react-icons/bi';
import { MdArrowBackIos } from 'react-icons/md';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as dayjs from 'dayjs';

const userData = JSON.parse(localStorage.getItem('_userData'));
const USER_TYPE = userData && userData.data.user_type;

function withParams(Component) {
  return (props) => <Component {...props} location={useLocation()} navigation={useNavigate()} />;
}

class Form extends Component {
  state = {
    fields: [
      {
        name: 'user_type',
        type: 'hidden',
        value: USER_TYPE,
        validation: {
          required: true,
        },
        static: true,
        valid: true,
      },
      {
        name: 'child_id',
        type: 'hidden',
        value: '',
        validation: {
          required: true,
        },
        static: true,
        valid: true,
      },
      {
        name: 'batch_number',
        type: 'hidden',
        value: '',
        validation: {
          required: true,
        },
        static: true,
        valid: true,
      },
      {
        type: 'section',
        value: "Batch's Details",
        valid: true,
        touched: true,
        colspan: 'col-span-2',
      },
      /*  {
            name: 'ccc',
            label: 'Batch',
            type: 'text',
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        }, */
      {
        name: 'sport_unleash_test_batch',
        label: 'Batch ID',
        type: 'customSelect',
        value: '',
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
      },
      {
        type: 'section',
        value: "Child's Details",
        valid: true,
        touched: true,
        colspan: 'col-span-2',
      },
      {
        name: 'child_first_name',
        label: 'First Name',
        type: 'text',
        value: '',
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
      },
      {
        name: 'child_last_name',
        label: 'Last Name',
        type: 'text',
        value: '',
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
      },
      {
        name: 'child_date_of_birth',
        label: 'Date of Birth',
        type: 'date',
        value: '',
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
      },
      {
        name: 'school_name',
        label: 'School Name',
        type: 'text',
        value: '',
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
      },
      {
        name: 'parent_address',
        label: 'Address',
        type: 'text',
        value: '',
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
      },
      {
        name: 'postal_code',
        label: 'Postal Code',
        type: 'text',
        value: '',
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
      },
      {
        name: 'sports_currently_doing',
        label: 'Sports Currently Doing',
        type: 'textarea',
        value: '',
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
        colspan: 'col-span-2',
      },
      {
        name: 'guardian',
        label: "I'm a guardian",
        type: 'checkbox',
        value: false,
        validation: {
          required: false,
        },
        valid: true,
        touched: false,
        colspan: 'col-span-2',
      },
      {
        name: 'guardian_email',
        label: "Guardian's Email",
        tempType: 'email',
        type: 'hidden',
        value: '',
        validation: {
          required: false,
        },
        valid: true,
        touched: false,
      },
      {
        type: 'section',
        value: "Father's Details",
        valid: true,
        touched: true,
        colspan: 'col-span-2',
      },
      {
        name: 'fathers_name',
        label: "Father's Name",
        type: 'text',
        value: '',
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
      },
      {
        name: 'fathers_date_of_birth',
        label: 'Date of Birth',
        type: 'date',
        value: '',
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
      },
      {
        name: 'fathers_contact',
        label: 'Contact Number',
        type: 'tel',
        value: '',
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
      },
      {
        name: 'fathers_height',
        label: 'Height',
        type: 'number',
        value: '',
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
      },
      {
        name: 'fathers_weight',
        label: 'Weight',
        type: 'number',
        value: '',
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
      },
      {
        name: 'fathers_sports_played',
        label: 'Sports Played',
        type: 'text',
        value: '',
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
      },
      {
        type: 'section',
        value: "Mother's Details",
        valid: true,
        touched: true,
        colspan: 'col-span-2',
      },
      {
        name: 'mothers_name',
        label: "Mother's Name",
        type: 'text',
        value: '',
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
      },
      {
        name: 'mothers_date_of_birth',
        label: 'Date of Birth',
        type: 'date',
        value: '',
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
      },
      {
        name: 'mothers_contact',
        label: 'Contact Number',
        type: 'tel',
        value: '',
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
      },
      {
        name: 'mothers_height',
        label: 'Height',
        type: 'number',
        value: '',
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
      },
      {
        name: 'mothers_weight',
        label: 'Weight',
        type: 'number',
        value: '',
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
      },
      {
        name: 'mothers_sports_played',
        label: 'Sports Played',
        type: 'text',
        value: '',
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
      },
    ],
    fieldsSet2: [
      {
        name: 'user_type',
        type: 'hidden',
        value: USER_TYPE,
        validation: {
          required: true,
        },
        static: true,
        valid: true,
      },
      {
        name: 'child_id',
        type: 'hidden',
        value: '',
        validation: {
          required: true,
        },
        static: true,
        valid: true,
      },
      {
        name: 'test_id',
        type: 'hidden',
        value: '',
        validation: {
          required: true,
        },
        static: true,
        valid: true,
      },
      {
        type: 'section',
        value: 'Student Measurements',
        valid: true,
        touched: true,
        colspan: 'col-span-6',
      },
      {
        name: 'height',
        label: 'Height',
        type: 'number',
        value: '',
        validation: {
          required: false,
        },
        valid: true,
        touched: false,
        colspan: 'col-span-3',
      },
      {
        name: 'weight',
        label: 'Weight',
        type: 'number',
        value: '',
        validation: {
          required: false,
        },
        valid: true,
        touched: false,
        colspan: 'col-span-3',
      },
      {
        name: 'fingers_second',
        label: 'Fingers Second',
        type: 'number',
        value: '',
        validation: {
          required: false,
        },
        valid: true,
        touched: false,
        colspan: 'col-span-3',
      },
      {
        name: 'fingers_third',
        label: 'Fingers Third',
        type: 'number',
        value: '',
        validation: {
          required: false,
        },
        valid: true,
        touched: false,
        colspan: 'col-span-3',
      },
      {
        name: 'fingers_fourth',
        label: 'Fingers Fourth',
        type: 'number',
        value: '',
        validation: {
          required: false,
        },
        valid: true,
        touched: false,
        colspan: 'col-span-3',
      },
      {
        name: 'wrist_size',
        label: 'Wrist Size',
        type: 'number',
        value: '',
        validation: {
          required: false,
        },
        valid: true,
        touched: false,
        colspan: 'col-span-3',
      },
      {
        name: 'tip_of_middle_finger_to_wrist',
        label: 'Tip of Middle Finger to Wrist',
        type: 'number',
        value: '',
        validation: {
          required: false,
        },
        valid: true,
        touched: false,
        colspan: 'col-span-3',
      },

      {
        name: 'wrist_to_elbow',
        label: 'Wrist to Elbow',
        type: 'number',
        value: '',
        validation: {
          required: false,
        },
        valid: true,
        touched: false,
        colspan: 'col-span-3',
      },
      {
        name: 'elbow_to_shoulder',
        label: 'Elbow to Shoulder',
        type: 'number',
        value: '',
        validation: {
          required: false,
        },
        valid: true,
        touched: false,
        colspan: 'col-span-3',
      },
      {
        name: 'shoulder_to_shoulder',
        label: 'Shoulder to Shoulder',
        type: 'number',
        value: '',
        validation: {
          required: false,
        },
        valid: true,
        touched: false,
        colspan: 'col-span-3',
      },
      {
        name: 'shoulder_to_hip',
        label: 'Shoulder to Hip',
        type: 'number',
        value: '',
        validation: {
          required: false,
        },
        valid: true,
        touched: false,
        colspan: 'col-span-3',
      },
      {
        name: 'hip_to_knee',
        label: 'Hip to Knee',
        type: 'number',
        value: '',
        validation: {
          required: false,
        },
        valid: true,
        touched: false,
        colspan: 'col-span-3',
      },
      {
        name: 'knee_to_heel',
        label: 'Knee to Heel',
        type: 'number',
        value: '',
        validation: {
          required: false,
        },
        valid: true,
        touched: false,
        colspan: 'col-span-3',
      },
      {
        name: 'waist',
        label: 'Waist',
        type: 'number',
        value: '',
        validation: {
          required: false,
        },
        valid: true,
        touched: false,
        colspan: 'col-span-3',
      },
      {
        type: 'section',
        value: 'Activity Measurements',
        valid: true,
        touched: true,
        colspan: 'col-span-6',
      },
      {
        name: 'vertical_jump_1',
        label: 'Vertical Jump (cm)',
        type: 'number',
        value: '',
        placeholder: '1st Attempt',
        validation: {
          required: false,
        },
        valid: true,
        touched: false,
      },
      {
        name: 'vertical_jump_2',
        label: '2nd Attempt',
        hidelabel: true,
        placeholder: '2nd Attempt',
        type: 'number',
        value: '',

        validation: {
          required: false,
        },
        valid: true,
        touched: false,
      },
      {
        name: 'vertical_jump_3',
        label: '3rd Attempt',
        hidelabel: true,
        placeholder: '3rd Attempt',
        type: 'number',
        value: '',
        validation: {
          required: false,
        },
        valid: true,
        touched: false,
      },
      {
        name: 'standing_long_jump_1',
        label: 'Standing Long Jump (m)',
        placeholder: '1st Attempt',
        type: 'number',
        value: '',
        validation: {
          required: false,
        },
        valid: true,
        touched: false,
      },
      {
        name: 'standing_long_jump_2',
        label: '2nd Attempt',
        hidelabel: true,
        placeholder: '2nd Attempt',
        type: 'number',
        value: '',
        validation: {
          required: false,
        },
        valid: true,
        touched: false,
      },
      {
        name: 'standing_long_jump_3',
        label: '3rd Attempt',
        hidelabel: true,
        placeholder: '3rd Attempt',
        type: 'number',
        value: '',
        validation: {
          required: false,
        },
        valid: true,
        touched: false,
      },
      {
        name: '30_meter_dash_1',
        label: '30 Meter dash (seconds)',
        placeholder: '1st Attempt',
        type: 'number',
        value: '',
        validation: {
          required: false,
        },
        valid: true,
        touched: false,
      },
      {
        name: '30_meter_dash_2',
        label: '2nd Attempt',
        hidelabel: true,
        placeholder: '2nd Attempt',
        type: 'number',
        value: '',
        validation: {
          required: false,
        },
        valid: true,
        touched: false,
      },
      {
        name: '30_meter_dash_3',
        label: '3rd Attempt',
        hidelabel: true,
        placeholder: '3rd Attempt',
        type: 'number',
        value: '',
        validation: {
          required: false,
        },
        valid: true,
        touched: false,
      },
      {
        name: '20kg_medicine_ball_throw_1',
        label: '2Kg medicine ball throw (m)',
        placeholder: '1st Attempt',
        type: 'number',
        value: '',
        validation: {
          required: false,
        },
        valid: true,
        touched: false,
      },
      {
        name: '20kg_medicine_ball_throw_2',
        label: '2nd Attempt',
        hidelabel: true,
        placeholder: '2nd Attempt',
        type: 'number',
        value: '',
        validation: {
          required: false,
        },
        valid: true,
        touched: false,
      },
      {
        name: '20kg_medicine_ball_throw_3',
        label: '3rd Attempt',
        hidelabel: true,
        placeholder: '3rd Attempt',
        type: 'number',
        value: '',
        validation: {
          required: false,
        },
        valid: true,
        touched: false,
      },
      {
        name: 'small_ball_throw_1',
        label: 'Small ball throw (m)',
        placeholder: '1st Attempt',
        type: 'number',
        value: '',
        validation: {
          required: false,
        },
        valid: true,
        touched: false,
      },
      {
        name: 'small_ball_throw_2',
        label: '2nd Attempt',
        hidelabel: true,
        placeholder: '2nd Attempt',
        type: 'number',
        value: '',
        validation: {
          required: false,
        },
        valid: true,
        touched: false,
      },
      {
        name: 'small_ball_throw_3',
        label: '3rd Attempt',
        hidelabel: true,
        placeholder: '3rd Attempt',
        type: 'number',
        value: '',
        validation: {
          required: false,
        },
        valid: true,
        touched: false,
      },
      {
        type: 'section',
        value: 'Test Image',
        valid: true,
        touched: true,
        colspan: 'col-span-6',
      },
      {
        name: 'test_image',
        label: 'Upload Test Image',
        type: 'file',
        value: '',
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
        colspan: 'col-span-3',
      },
    ],
    settings: null,
    isSubmitting: false,
    isLoading: false,
    isVerifing: false,
    isVerifid: false, //false
    isOnStep2: false,
    formValid: false,
    formValid2: false,
    successMessage: '',
    apiError: '',
    token: null,
    fileData: null,
    student_id: '',
    student_data: [],
    countries: [],
  };

  constructor(props) {
    super(props);
    this.infoRef = createRef();

    const { state } = this.props.location;
    if (state) {
      this.state.student_id = state;
    }
  }

  async componentDidMount() {
    const countryList = await getCountries();
    const batchList = await getBatches();

    if (countryList.length) {
      this.state.fields.map((field, index) => {
        if (field.name === 'country') {
          field.options = countryList;
        }
      });
    }

    //console.log(batchList);

    if (batchList.length) {
      this.state.fields.map((field, index) => {
        if (field.name === 'sport_unleash_test_batch') {
          field.options = batchList;
        }
      });
    }
  }

  fieldChange = (event, field, index, fData, fValid) => {
    const updatedField = { ...field };
    updatedField.value = event && event.target.value;

    if (field.type === 'country' || field.type === 'tel' || field.type === 'customSelect') {
      updatedField.value = field.value;
    }

    if (field.type === 'file') {
      this.state.fileData = event.target.files[0];
    }

    if (field.name === 'guardian') {
      updatedField.value = event.target.checked ? true : false;

      const guardianEmail = this.state.fields.filter((field, index) => field?.name == 'guardian_email');
      const fields = this.state.fields.filter((field, index) => /^mothers_|fathers_/.test(field?.name));

      if (event.target.checked) {
        console.log('checked');

        fields.map((item) => {
          console.log(item);

          item.validation.required = false;
          item.valid = true;
          item.temptype = item.type;
          item.type = 'hidden';
        });

        guardianEmail[0].validation.required = true;
        guardianEmail[0].valid = false;
        guardianEmail[0].type = 'email';
      } else {
        fields.map((item) => {
          item.validation.required = true;
          item.valid = false;
          item.type = item.temptype;
        });

        guardianEmail[0].validation.required = false;
        guardianEmail[0].valid = true;
        guardianEmail[0].temptype = guardianEmail[0].type;
        guardianEmail[0].type = 'hidden';

        console.log('unchecked');
      }
    }

    console.log(field);

    if (field.name === 'sport_unleash_test_batch') {
      // item.value = stData.school_id;
      updatedField.helper = field.base + '_' + updatedField.value;

      console.log(field.value);
    }

    updatedField.valid = this.checkValidity(updatedField);

    const updatedFields = [...this.state[fData]];
    updatedFields.splice(index, 1, updatedField);
    let formValid = true;
    for (let field of updatedFields) {
      if (!field.valid) {
        formValid = false;
      }
    }
    this.setState({
      [fData]: updatedFields,
      [fValid]: formValid,
    });
  };

  checkValidity = (field) => {
    const rules = field.validation;
    const value = field.value;
    let isValid = true;

    if (field.type === 'section') {
      return true;
    }

    if (!rules) {
      return true;
    }
    if (rules.required) {
      isValid = value?.toString().trim() !== '' && isValid;
    }
    if (rules.minLength) {
      isValid = value.length >= rules.minLength && isValid;
    }
    if (rules.maxLength) {
      isValid = value.length <= rules.maxLength && isValid;
    }
    if (rules.pattern) {
      isValid = rules.pattern.test(value) && isValid;
    }
    if (field.name === 'password_confirmation') {
      const pwdItem = this.state.fields.filter((field, index) => field.name === 'password');
      let currPwd = pwdItem[0].value;

      //console.log(field)
      isValid = value === currPwd && isValid;
    }

    /* if (field.name === 'password') {
          
            const pwdItem = this.state.fields.filter((field, index) => field.name === 'password_confirmation');

            console.log(pwdItem[0]);
            this.checkValidity(pwdItem[0])
        } */

    return isValid;
  };
  fieldBlur(event, field, index, fData) {
    if (field.touched) {
      return;
    }
    const updatedField = { ...field };
    updatedField.touched = true;
    updatedField.valid = this.checkValidity(updatedField);
    const updatedFields = [...this.state[fData]];
    updatedFields.splice(index, 1, updatedField);
    this.setState({
      [fData]: updatedFields,
    });
  }
  onSubmit = (event, fData, fValid, step) => {
    console.log(this.state[fData]);

    if (this.state[fValid]) {
      this.sendData(fData, step);
    } else {
      this.state[fData].map((field, index) => {
        this.checkValidity(field);
        field.touched = true;
      });

      this.setState({ [fData]: this.state[fData] });
    }
    event.preventDefault();
  };

  clearForm = (fData) => {
    this.state[fData].map((field, index) => {
      if (!field.static) {
        field.value = '';
      }
    });
  };

  sendData = async (fData, step) => {
    const formData = new FormData();
    const { navigation } = this.props;

    console.log('current step', step);

    this.state[fData].map((field, index) => {
      if (field.name && field.value) {
        if (field.type === 'file') {
          formData.append(field.name, this.state.fileData);
        } else {
          formData.append(field.name, field.value);
        }
      }
    });

    // console.log(dataObj);

    if (step === 2) {
      this.state.fields.map((field, index) => {
        if (field.name && field.value) {
          formData.append(field.name, field.value);
        }
      });

      console.log('get fied set one data ');
    }

    console.log(formData);

    let that = this;
    that.setState({ isSubmitting: true });

    axios
      .post(api.testAdd, formData)
      .then(function (response) {
        let data = response.data;

        console.log(data);
        if (data.success && data.data.test_id) {
          if (step === 1) {
            that.setState({ isOnStep2: true });

            that.state.fieldsSet2.map((item) => {
              if (item.name === 'test_id') {
                item.value = data.data.test_id;
                item.valid = true;
                item.touched = true;
              }
            });
          } else {
            that.setState({ successMessage: data.message });
            that.clearForm(fData);

            toast.success(data.message, {
              autoClose: 3000,
              onClose: () => navigation('/test-management'),
            });
          }

          //Scroll to top
          window.scrollTo(0, 0);
        } else {
          that.setState({ apiError: data.message });
        }

        that.setState({ isSubmitting: false });
      })
      .catch(function (error) {
        // that.setState({ isSubmitting: false, apiError: 'Unable to connect with the api.' });

        if (error.response.status == 422) {
          toast.error(error.response.data.test_id, {
            autoClose: 3000,
          });
          // setApiError('Incorrect Student ID');
        } else {
          toast.error('Test Create failed', {
            autoClose: 3000,
          });
          // setApiError(error.response.data.test_id);
        }

        console.log(error);
      });
  };

  handleChange = (event) => {
    const target = event.target;
    const val = target.value ? target.value : '';
    const value = target.type === 'checkbox' ? target.checked : val;
    const name = target.name;
    this.setState({ [name]: value });
  };

  backToStep1 = (event) => {
    this.setState({ isOnStep2: false });
  };

  onVeryfy = (event) => {
    const that = this;
    event.preventDefault();

    this.infoRef.current = toast.info('Processing your request...', {
      progress: 100,
      closeButton: false,
      autoClose: false,
      icon: <BiLoaderAlt className="animate-spin h-6 w-6 text-primary" />,
    });

    this.setState({ isVerifing: true });

    axios
      .post(api.studentVerfiy, { child_id: this.state.student_id })
      .then(function (response) {
        toast.dismiss(that.infoRef.current);

        let data = response.data;

        console.log(response);
        if (data.success) {
          that.setState({ isVerifid: true });

          const stData = data.data;
          that.state.fields.map((item) => {
            if (item.name === 'child_id' && stData.child_id) {
              item.value = stData.child_id;
              item.valid = true;
              item.touched = true;
            }
            if (item.name === 'child_first_name' && stData.first_name) {
              item.value = stData.first_name;
              item.valid = true;
            }
            if (item.name === 'child_last_name' && stData.last_name) {
              item.value = stData.last_name;
              item.valid = true;
            }
            if (item.name === 'child_date_of_birth' && stData.child_date_of_birth) {
              item.value = stData.child_date_of_birth;
              item.valid = true;
            }
            if (item.name === 'school_name' && stData.school_name) {
              item.value = stData.school_name;
              item.valid = true;
            }
            if (item.name === 'sports_currently_doing' && stData.sports_currently_doing) {
              item.value = stData.sports_currently_doing;
              item.valid = true;
            }

            console.log(item);
          });

          that.state.fieldsSet2.map((item) => {
            if (item.name === 'child_id' && stData.child_id) {
              item.value = stData.child_id;
              item.valid = true;
              item.touched = true;
            }
          });
        } else {
          toast.error(data.message);
        }

        that.setState({ isVerifing: false });
      })
      .catch(function (error) {
        toast.dismiss(that.infoRef.current);

        if (error?.response?.data?.data[Object.keys(error.response.data.data)[0]][0]) {
          toast.error(error?.response?.data?.data[Object.keys(error.response.data.data)[0]][0]);
        } else {
          const mzg = error.response.data.message ? error.response.data.message : 'Unable to connect with the api. ';
          toast.error(mzg);
        }

        toast.dismiss(that.infoRef.current);
        // toast.error(`Unable to connect with the api.`);
        that.setState({ isVerifing: false });
        console.log(error);
        /* 
             that.setState({ isVerifid:true });
            const stData = {
                "id": 14,
                "child_id": "15ebb251e4b6ac3915c4add206784e93",
                "first_name": "wewwwe",
                "last_name": "JAKEKEKE",
                "school_id": "11122233444666788900",
                "date_of_birth": "1975/04/01",
                "school_name": "D.S Senanayake College",
                "registered_by": "3cae2853b78135b864b57adea5857803",
                "registered_by_type": "ADMIN",
                "Sports": null,
                "created_at": "2023-03-24T14:29:47.000000Z",
                "updated_at": null,
                "deleted_at": null
            };

            that.state.fields.map( item => {


                if(item.name === 'child_id' && stData.child_id){
                    item.value = stData.child_id;
                    item.valid = true;
                    item.touched = true;
                }
                if(item.name === 'child_first_name' && stData.first_name){
                    item.value = stData.first_name;
                    item.valid = true;
                }
                if(item.name === 'child_last_name' && stData.last_name){
                    item.value = stData.last_name;
                    item.valid = true;
                }
                if(item.name === 'child_date_of_birth' && stData.date_of_birth){
                    item.value = stData.date_of_birth;
                    item.valid = true;
                }
                if(item.name === 'school_name' && stData.school_name){
                    item.value = stData.school_name;
                    item.valid = true;
                }
                if(item.name === 'sports_currently_doing' && stData.Sports){
                    item.value = stData.Sports;
                    item.valid = true;
                }

                //Set Batch ID
                if(item.name === 'sport_unleash_test_batch' && stData.school_id){
                    item.base = dayjs().year() + '_' + stData.school_id;
                    item.helper = item.base;
                }
            })
            
            that.state.fieldsSet2.map( item => {

                if(item.name === 'child_id' && stData.child_id){
                    item.value = stData.child_id;
                    item.valid = true;
                    item.touched = true;
                }
            
            })  */
      });
  };

  render() {
    const { isLoading, isVerifing, isVerifid, isOnStep2, student_id } = this.state;
    return (
      <>
        <div className="mx-auto mt-4">
          <div className="w-full">
            <div className={`${isOnStep2 ? 'hidden' : 'block'}`}>
              <form onSubmit={(event) => this.onVeryfy(event)}>
                <div className="md:grid md:grid-cols-2 gap-x-8 gap-y-4">
                  <input
                    value={student_id}
                    name="student_id"
                    onChange={this.handleChange}
                    type="text"
                    className="bg-primary/5 border-black/10   appearance-none border  rounded-lg w-full py-3 px-4 h-12 text-body leading-tight focus:outline-none focus:border-primary"
                    placeholder="Enter your student ID"
                  />
                  <div>
                    <button
                      type="submit"
                      className={`mt-4 md:mt-0 inline-block btn bg-primary shadow-md py-3 px-5 rounded-md text-white hover:shadow-lg hover:opacity-80 ${
                        isVerifing || (!student_id && 'opacity-50 pointer-events-none')
                      }`}
                    >
                      Verify Student
                    </button>
                  </div>
                </div>
              </form>

              <form
                onSubmit={(event) => this.onSubmit(event, 'fields', 'formValid', 1)}
                className={`${isVerifid ? 'block' : 'hidden'}`}
              >
                <div className="md:grid md:grid-cols-2 gap-x-8 gap-y-4 mt-8">
                  {this.state.fields.map((field, index) => {
                    return (
                      <Field
                        key={index}
                        fieldConfig={field}
                        blured={(event) => this.fieldBlur(event, field, index, 'fields')}
                        changed={(event) => this.fieldChange(event, field, index, 'fields', 'formValid')}
                      />
                    );
                  })}
                </div>

                <div className="">
                  {isLoading ? <Loader /> : <></>}

                  <>
                    {!this.state.isSubmitting && (
                      <div className="flex justify-end items-center mt-8 mb-8 gap-x-4">
                        {this.state.successMessage ? (
                          <span className="text-success text-base italic">{this.state.successMessage} </span>
                        ) : (
                          this.state.apiError && (
                            <span className="text-error text-base italic">{this.state.apiError} </span>
                          )
                        )}

                        <button
                          type="submit"
                          className={`inline-block btn bg-primary shadow-md py-3 px-5 rounded-md text-white hover:shadow-lg hover:opacity-80 ${
                            isLoading && 'opacity-50 pointer-events-none'
                          }`}
                          data-disabled={!this.state.formValid}
                        >
                          Next
                        </button>
                        <Link
                          to={'/test-management'}
                          className={`inline-block btn border border-primary bg-transparent shadow-md py-3 px-5 rounded-md text-primary hover:shadow-lg hover:opacity-80 ${
                            isLoading && 'opacity-50 pointer-events-none'
                          }`}
                          data-disabled={!this.state.formValid}
                        >
                          Cancel
                        </Link>
                      </div>
                    )}
                    {this.state.isSubmitting && (
                      <div className="flex justify-end items-start mt-8 gap-x-4">
                        <button
                          type="button"
                          className="btn bg-primary py-3 px-5 rounded-md text-white shadow-lg opacity-80 flex items-center justify-center cursor-default"
                        >
                          <BiLoaderAlt className="animate-spin h-5 w-5 mr-2" /> Processing...
                        </button>
                      </div>
                    )}
                  </>
                </div>
              </form>
            </div>

            <form
              onSubmit={(event) => this.onSubmit(event, 'fieldsSet2', 'formValid2', 2)}
              className={`${isOnStep2 ? 'block' : 'hidden'}`}
            >
              <div className="md:grid md:grid-cols-6 gap-x-8 gap-y-4 mt-8">
                {this.state.fieldsSet2.map((field, index) => {
                  return (
                    <Field
                      key={index}
                      fieldConfig={field}
                      blured={(event) => this.fieldBlur(event, field, index, 'fieldsSet2')}
                      changed={(event) => this.fieldChange(event, field, index, 'fieldsSet2', 'formValid2')}
                    />
                  );
                })}
              </div>

              <div className="">
                {isLoading ? <Loader /> : <></>}

                <>
                  {!this.state.isSubmitting && (
                    <div className="flex justify-end items-center mt-8 mb-8 gap-x-4">
                      {this.state.successMessage ? (
                        <span className="text-success text-base italic">{this.state.successMessage} </span>
                      ) : (
                        this.state.apiError && (
                          <span className="text-error text-base italic">{this.state.apiError} </span>
                        )
                      )}

                      <button
                        type="button"
                        onClick={(event) => this.backToStep1(event)}
                        className={`inline-block btn border border-primary bg-transparent shadow-md py-3 px-5 rounded-md text-primary hover:shadow-lg hover:opacity-80 ${
                          isLoading && 'opacity-50 pointer-events-none'
                        }`}
                      >
                        Back
                      </button>
                      <button
                        type="submit"
                        className={`inline-block btn bg-primary shadow-md py-3 px-5 rounded-md text-white hover:shadow-lg hover:opacity-80 ${
                          isLoading && 'opacity-50 pointer-events-none'
                        }`}
                        data-disabled={!this.state.formValid2}
                      >
                        Submit
                      </button>
                    </div>
                  )}
                  {this.state.isSubmitting && (
                    <div className="flex justify-end items-start mt-8 gap-x-4">
                      <button
                        type="button"
                        className="btn bg-primary py-3 px-5 rounded-md text-white shadow-lg opacity-80 flex items-center justify-center cursor-default"
                      >
                        <BiLoaderAlt className="animate-spin h-5 w-5 mr-2" /> Processing...
                      </button>
                    </div>
                  )}
                </>
              </div>
            </form>
          </div>
        </div>
        <ToastContainer />
      </>
    );
  }
}

export default withParams(Form);
