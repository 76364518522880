import React, { Component } from 'react';
import Field from './Field';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { getCountries } from '../services/getCountries';
import { api } from '../Settings';
import Loader from './../components/Loader';
import { BiLoaderAlt } from 'react-icons/bi';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const userData = JSON.parse(localStorage.getItem('_userData'));
const USER_TYPE = userData && userData.data.user_type;

function withParams(Component) {
  return (props) => <Component {...props} navigation={useNavigate()} />;
}

class Form extends Component {
  state = {
    fields: [
      {
        name: 'user_type',
        type: 'hidden',
        value: USER_TYPE,
        validation: {
          required: true,
        },
        static: true,
        valid: true,
      },
      {
        name: 'first_name',
        label: 'First Name',
        type: 'text',
        value: '',
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
      },
      {
        name: 'last_name',
        label: 'Last Name',
        type: 'text',
        value: '',
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
      },
      {
        name: 'school_name',
        label: 'School Name',
        type: 'text',
        value: '',
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
      },
      {
        name: 'gender',
        label: 'Gender',
        type: 'radio',
        value: '',
        options: [
          {
            id: 'male',
            name: 'Male',
          },
          {
            id: 'female',
            name: 'Female',
          },
        ],
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
      },
      {
        name: 'date_of_birth',
        label: 'Date of Birth',
        type: 'date',
        value: '',
        validation: {
          required: false,
        },
        valid: true,
        touched: false,
      },
      {
        name: 'email',
        label: 'Email ',
        type: 'email',
        value: '',
        validation: {
          required: true,
          pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
        },
        valid: false,
        touched: false,
      },
      {
        name: 'contact_number',
        label: 'Contact Number',
        type: 'tel',
        value: '',
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
      },
      {
        name: 'address_line1',
        label: 'Address Line 1',
        type: 'text',
        value: '',
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
      },
      {
        name: 'address_line2',
        label: 'Address Line 2',
        type: 'text',
        value: '',
        validation: {
          required: false,
        },
        valid: true,
        touched: false,
      },
      {
        name: 'city',
        label: 'City',
        type: 'text',
        value: '',
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
      },
      {
        name: 'postal_code',
        label: 'Postal Code',
        type: 'text',
        value: '',
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
      },
      {
        name: 'country',
        label: 'Country',
        type: 'country',
        value: '',
        validation: {
          required: true,
        },
        touched: false,
      },
      {
        name: 'password',
        label: 'Password',
        type: 'password',
        value: '',
        validation: {
          //pattern: /^(?=.*\d)(?=.*[a-zA-Z]).{8,}$/,
          required: true,
        },
        valid: false,
        touched: false,
        errorMessage: 'Use at least 8 characters, Password should contain numbers and letters.',
      },
      {
        name: 'password_confirmation',
        label: 'Confirm Password',
        type: 'password',
        value: '',
        validation: {
          //pattern: /^(?=.*\d)(?=.*[a-zA-Z]).{8,}$/,
          required: true,
        },
        valid: false,
        touched: false,
      },
    ],
    settings: null,
    isSubmitting: false,
    isLoading: false,
    formValid: false,
    successMessage: '',
    apiError: '',
    token: null,
    countries: [],
  };

  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    const countryList = await getCountries();
    if (countryList.length) {
      this.state.fields.map((field, index) => {
        if (field.name === 'country') {
          field.options = countryList;
        }
      });
    }
  }

  fieldChange = (event, field, index) => {
    const updatedField = { ...field };
    updatedField.value = event && event.target.value;

    if (field.type === 'country' || field.type === 'tel') {
      updatedField.value = field.value;
    }

    updatedField.valid = this.checkValidity(updatedField);

    const updatedFields = [...this.state.fields];
    updatedFields.splice(index, 1, updatedField);
    let formValid = true;
    for (let field of updatedFields) {
      if (!field.valid) {
        formValid = false;
      }
    }
    this.setState({
      fields: updatedFields,
      formValid: formValid,
    });
  };

  checkValidity = (field) => {
    const rules = field.validation;
    const value = field.value;
    let isValid = true;

    //console.log(field.name);

    if (!rules) {
      return true;
    }
    if (rules.required) {
      isValid = value?.toString().trim() !== '' && isValid;
    }
    if (rules.minLength) {
      isValid = value.length >= rules.minLength && isValid;
    }
    if (rules.maxLength) {
      isValid = value.length <= rules.maxLength && isValid;
    }
    if (rules.pattern) {
      isValid = rules.pattern.test(value) && isValid;
    }
    if (field.name === 'password_confirmation') {
      const pwdItem = this.state.fields.filter((field, index) => field.name === 'password');
      let currPwd = pwdItem[0].value;

      //console.log(field)
      isValid = value === currPwd && isValid;
    }

    if (field.name === 'password') {
      const cpwdItem = this.state.fields.filter((field, index) => field.name === 'password_confirmation');
      if (cpwdItem[0].value !== field.value) {
        cpwdItem[0].valid = false;
        cpwdItem[0].touched = true;
      }
    }

    return isValid;
  };
  fieldBlur(event, field, index) {
    if (field.touched) {
      return;
    }
    const updatedField = { ...field };
    updatedField.touched = true;
    updatedField.valid = this.checkValidity(updatedField);
    const updatedFields = [...this.state.fields];
    updatedFields.splice(index, 1, updatedField);
    this.setState({
      fields: updatedFields,
    });
  }
  onSubmit = (event) => {
    console.log(this.state.fields);

    if (this.state.formValid) {
      this.sendData();
    } else {
      this.state.fields.map((field, index) => {
        this.checkValidity(field);
        field.touched = true;
      });

      this.setState({ fields: this.state.fields });
    }
    event.preventDefault();
  };

  clearForm = () => {
    this.state.fields.map((field, index) => {
      if (!field.static) {
        field.value = '';
      }
    });
  };

  sendData = async () => {
    const dataObj = {};
    const { navigation } = this.props;

    this.state.fields.map((field, index) => {
      if (field.name && field.value) {
        dataObj[field.name] = field.value;
      }
    });

    let that = this;
    that.setState({ isSubmitting: true });

    axios
      .post(api.susRegister, dataObj)
      .then(function (response) {
        let data = response.data;
        if (data.success) {
          that.setState({ successMessage: data.message });
          that.clearForm();

          toast.success(data.message, {
            autoClose: 3000,
            onClose: () => navigation('/sus-management'),
          });
        } else {
          that.setState({ apiError: data.message });
        }

        that.setState({ isSubmitting: false });
      })
      .catch(function (error) {
        if (error.response.status == 422) {
          that.setState({ isSubmitting: false, apiError: 'Email has already been taken.' });
        } else {
          that.setState({ isSubmitting: false, apiError: 'Unable to connect with the api.' });
        }
        console.log(error);
        //that.setState({  successMessage: 'Congratulations, your account has been successfully created.' });
      });
  };

  render() {
    const { isLoading, settings } = this.state;
    return (
      <>
        <div className="mx-auto mt-4">
          <div className="w-full">
            <form onSubmit={(event) => this.onSubmit(event)}>
              <div className="md:grid md:grid-cols-2 gap-x-8 gap-y-4">
                {this.state.fields.map((field, index) => {
                  return (
                    <Field
                      key={index}
                      fieldConfig={field}
                      blured={(event) => this.fieldBlur(event, field, index)}
                      changed={(event) => this.fieldChange(event, field, index)}
                    />
                  );
                })}
              </div>

              <div className="">
                {isLoading ? <Loader /> : <></>}

                <>
                  {!this.state.isSubmitting && (
                    <div className="flex justify-end items-center mt-8 mb-8 gap-x-4">
                      {this.state.successMessage ? (
                        <span className="text-success text-base italic">{this.state.successMessage} </span>
                      ) : (
                        this.state.apiError && (
                          <span className="text-error text-base italic">{this.state.apiError} </span>
                        )
                      )}

                      <button
                        type="submit"
                        className={`inline-block btn bg-primary shadow-md py-3 px-5 rounded-md text-white hover:shadow-lg hover:opacity-80 ${
                          isLoading && 'opacity-50 pointer-events-none'
                        }`}
                        data-disabled={!this.state.formValid}
                      >
                        Submit
                      </button>
                      <Link
                        to={'/sus-management'}
                        className={`inline-block btn border border-primary bg-transparent shadow-md py-3 px-5 rounded-md text-primary hover:shadow-lg hover:opacity-80 ${
                          isLoading && 'opacity-50 pointer-events-none'
                        }`}
                        data-disabled={!this.state.formValid}
                      >
                        Cancel
                      </Link>
                    </div>
                  )}
                  {this.state.isSubmitting && (
                    <div className="flex justify-end items-start mt-8 gap-x-4">
                      <button
                        type="button"
                        className="btn bg-primary py-3 px-5 rounded-md text-white shadow-lg opacity-80 flex items-center justify-center cursor-default"
                      >
                        <BiLoaderAlt className="animate-spin h-5 w-5 mr-2" /> Submitting...
                      </button>
                    </div>
                  )}
                </>
              </div>
            </form>
          </div>
        </div>
        <ToastContainer />
      </>
    );
  }
}

export default withParams(Form);
