import axios from 'axios';
import { api } from '../Settings';

export const updateCountries = async (data = null, type = null) => {
  try {
    const request = await axios.post(api.countriesUpdate, { ids: data, status: type }).catch((err) => {
      if (err.response && err.response.status === 401) {
        window.location.href = '/logout';
      }
    });

    const response = request.data;
    return response;
  } catch (error) {
    console.log(`updateCountries error: ${error}`);
    return false;
  }
};
