import React, { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link, Outlet } from 'react-router-dom';
import Footer from '../../components/Footer';
import ApiError from '../../components/ApiError';
import Loader from '../../components/Loader';
import { CurrentTitle } from '../../atoms';
import { Lang, Drawer } from '../../components';
import { ToastContainer, toast } from 'react-toastify';
import { TitleComp } from './titleComp';

const BaseComp = () => {
  const [active, setActive] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState([]);

  return (
    <HelmetProvider>
      <div>
        <TitleComp />
        <div className="container mx-auto lg:flex  bg-bg">
          <Drawer />
          <Lang />
          <div className="w-full md:w-[calc(100%-84px)] 2xl:w-full md:ml-[84px] 2xl:ml-0 md:p-4 lg:p-8 min-h-screen relative pb-[90px] lg:pb-[100px] md:pb-[100px]">
            {isLoading ? (
              <Loader />
            ) : (
              <>
                <Outlet />
              </>
            )}
            {isError && <ApiError />}
            <Footer />
          </div>
        </div>
      </div>
      <ToastContainer />
    </HelmetProvider>
  );
};

export { BaseComp };
