import React, { Component, createRef } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import Drawer from './../components/Drawer';
import Footer from './../components/Footer';
import Table from './../components/Table';
import CountryListing from './../components/CountryListing';
import Breadcrumb from './../components/Breadcrumb';
import { updateCountries } from '../services/updateCountries';
import { BiLoaderAlt, BiWorld } from 'react-icons/bi';
import { confirmAlert } from 'react-confirm-alert'; // Impor
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MdAdd } from 'react-icons/md';
import Lang from '../components/Lang';
class ManageCountries extends Component {
  constructor(props) {
    super(props);
    this.infoRef = createRef();

    this.state = {
      active: true,
      isLoading: false,
      isError: false,
      selectedCountries: null,
      st: null,
      reload: false,
      data: [],
    };
  }

  chooseCountries = (data) => {
    this.setState({ selectedCountries: data, reload: true });
  };

  countryPopup = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="shadow-lg p-4 rounded-lg bg-white md:w-[450px] min-w-[300px]">
            <h1 className="text-2xl font-bold ">Add a Country</h1>
            <div className="my-4">
              <CountryListing type={'deactivated'} chooseCountries={this.chooseCountries} isMulti={true} />
            </div>
            <div className="flex items-center justify-end gap-2">
              <button
                onClick={onClose}
                className="btn border border-primary py-1.5 px-5 rounded-md text-primary  hover:bg-primary hover:text-white cursor-pointer "
              >
                Cancel
              </button>
              <button
                className="btn border border-primary py-1.5 px-5 rounded-md bg-primary text-white cursor-pointer"
                onClick={() => {
                  this.infoRef.current = toast.info('Processing your request...', {
                    progress: 100,
                    closeButton: false,
                    autoClose: false,
                    icon: <BiLoaderAlt className="animate-spin h-6 w-6 text-primary" />,
                  });
                  this.handleUpdateCounties();
                  onClose();
                }}
              >
                Add
              </button>
            </div>
          </div>
        );
      },
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  };

  async handleUpdateCounties() {
    const ids = [];
    this.state.selectedCountries?.map((c) => {
      ids.push(c.id);
    });

    const res = await updateCountries(ids, 'Active');
    toast.dismiss(this.infoRef.current);

    if (res) {
      toast.success(res.message);
      this.setState({ countryUpdate: ids });
    } else {
      toast.error(`Failed to update School status: ${res.message}`);
    }
  }

  onChangeSomeState = (newSomeState) => {
    //setSomeState(newSomeState);
    this.setState({ st: newSomeState });
  };

  render() {
    return (
      // <HelmetProvider>
      //   <div>
      //     <Helmet>
      //       <title>SportUnleash - Manage Countries</title>
      //     </Helmet>
      //     <div className="container mx-auto lg:flex  bg-bg">
      //       <Drawer />
      //       <div className="flex mx-auto flex-col bg-bg">
      //         <Lang />
      //         <div className="w-full md:w-[calc(100%-84px)] 2xl:w-full md:ml-[84px] 2xl:ml-0 md:p-4 lg:p-8 min-h-screen relative pb-[90px] lg:pb-[100px] md:pb-[100px]">
      //           <Breadcrumb />
      //           <div className="flex  items-center  justify-between mb-4">
      //             <h1 className="text-xl md:text-3xl font-bold italic">Manage Countries</h1>
      //             <a onClick={() => this.countryPopup()} className={`inline-block btn bg-primary shadow-md py-3 px-5 rounded-md text-white hover:shadow-lg hover:opacity-80`} >
      //               <MdAdd className="text-xl md:hidden" />
      //               <span className="hidden md:block"> Add a Country</span>
      //             </a>
      //           </div>
      //           <Table countryUpdate={this.state.countryUpdate} />
      //           <Footer />
      //           <ToastContainer />
      //         </div>
      //       </div>
      //     </div>

      //   </div>
      // </HelmetProvider>

      <>
        <Breadcrumb />
        <div className="flex  items-center  justify-between mb-4">
          <h1 className="text-xl md:text-3xl font-bold italic">Manage Countries</h1>
          <a
            onClick={() => this.countryPopup()}
            className={`inline-block btn bg-primary shadow-md py-3 px-5 rounded-md text-white hover:shadow-lg hover:opacity-80`}
          >
            <MdAdd className="text-xl md:hidden" />
            <span className="hidden md:block"> Add a Country</span>
          </a>
        </div>
        <Table countryUpdate={this.state.countryUpdate} />
      </>
    );
  }
}

export default ManageCountries;
