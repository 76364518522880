import { UpdatePathState, StateChangeComp } from '../../hooks/path';

const titleUpdate = (title) => {
  return <StateChangeComp title={title} />;
};

const getComponentData = () => {
  const userData = JSON.parse(localStorage.getItem('_userData'));
  const ASSCESS_TOKEN = userData && userData.data.token.access_token;
  const REFRESH_TOKEN = userData && userData.data.token.refresh_token;
  const USER_KEY = userData && userData.data.user_key;
  const USER_TYPE = userData && userData.data.user_type;
  const ADMIN_COUNTRIES =
    userData && USER_TYPE?.toUpperCase() === 'COUNTRY_ADMIN' && userData.data.admin_countries
      ? userData.data.admin_countries
      : null;
  return [ASSCESS_TOKEN, REFRESH_TOKEN, USER_KEY, USER_TYPE, ADMIN_COUNTRIES];
};

export { titleUpdate, getComponentData };
