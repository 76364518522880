import React, { Component } from 'react';
import axios from 'axios';
import { api } from '../Settings';
import ApiError from './ApiError';
import Loader from './Loader';
import { FiEdit } from 'react-icons/fi';

const userData = JSON.parse(localStorage.getItem('_userData'));
let EDIT_ACCESS = userData && userData.data.dash_admin ? true : false;

class Welcome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      hide: false,
      isLoading: false,
      isError: false,
    };

    this.hideContent = this.hideContent.bind(this);
    const visited = document.cookie
      .split('; ')
      .find((row) => row.startsWith('visited='))
      ?.split('=')[1];

    if (visited) {
      this.state.hide = true;
    }
  }

  componentDidMount() {
    const that = this;
    that.setState({ isLoading: true, isError: false });
    axios
      .get(api.getWelcome)
      .then((response) => {
        this.setState({
          data: response.data,
        });
        this.setState({ isLoading: false });
      })
      .catch(function (error) {
        that.setState({ isLoading: false, isError: true });
      });
  }

  hideContent(e) {
    this.setState({ hide: true });
    document.cookie = 'visited=true; Secure';
  }

  render() {
    const { data } = this.state;

    data.title = 'Great To Have You Back';
    data.description =
      '<p>With SU Admin Dashboard you can create new users, Manage schools, SUS, Parents,  Tests and more</p>';
    data.image = '/portal-manage/images/welcome.png';

    return (
      <>
        {data && (
          <div
            className={`overflow-hidden relative top-10 transition-all duration-300 bg-primary rounded-2xl min-h-[320px] ${
              this.state.hide ? 'max-h-0' : 'max-h-[1000px] relative'
            }`}
          >
            <div className={`pt-8 pb-8 p-4 2xl:p-16 text-white relative flex`}>
              <div className="w-7/12 pr-8">
                <h1 className="text-2xl md:text-5xl mb-8 font-semibold">{data.title}</h1>
                <div className="text-base" dangerouslySetInnerHTML={{ __html: data.description }}></div>
              </div>

              <div className="w-5/12">
                <img className="logo ml-auto w-auto max-h-[225px]" src={data.image} />
              </div>
            </div>
            {EDIT_ACCESS && data.editLink && (
              <a
                href={data.editLink}
                target="_blank"
                className="absolute right-0 top-0 z-[1] underline  bg-primary text-white px-2 py-1 hover:bg-primary/70 text-sm"
              >
                <FiEdit className="text-lg" title="Edit" />
              </a>
            )}
          </div>
        )}
      </>
    );
  }
}

export default Welcome;
