import React, { Component } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import Drawer from './../components/Drawer';
import Footer from './../components/Footer';
import Breadcrumb from './../components/Breadcrumb';
import { BiWorld } from 'react-icons/bi';
import Lang from '../components/Lang';

class ParentCreate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active: true,
      isLoading: false,
      isError: false,
      data: [],
    };
  }

  handleCallback = (drawer) => {
    this.setState({ active: drawer.show });
  };

  render() {
    return (
      <HelmetProvider>
        <div>
          <Helmet>
            <title>SportUnleash - Settings</title>
          </Helmet>
          <div className="container mx-auto lg:flex  bg-bg">
            <Drawer />
            <div className="flex w-full mx-auto flex-col bg-bg">
              <Lang />
              <div className="w-full md:w-[calc(100%-84px)] 2xl:w-full md:ml-[84px] 2xl:ml-0 md:p-4 lg:p-8 min-h-screen relative pb-[90px] lg:pb-[100px] md:pb-[100px]">
                <Breadcrumb />
                <div className="flex  items-center">
                  <h1 className="text-xl md:text-3xl font-bold italic">Settings</h1>
                </div>
                <div className="mt-4 grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6">
                  <Link
                    to="/settings/countries"
                    className="bg-white shadow rounded-lg h-0 pb-full flex flex-wrap items-center justify-center hover:shadow-lg hover:bg-primary/10 cursor-pointer transition relative"
                  >
                    <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-center">
                      <BiWorld className="text-primary w-20 h-20 xl:h-28 xl:w-28 mt-2 mx-auto" />
                      <h4 className="text-lg mt-1 md:whitespace-nowrap w-full">Manage Countries</h4>
                    </div>
                  </Link>
                </div>

                <Footer />
              </div>
            </div>
          </div>
        </div>
      </HelmetProvider>
    );
  }
}

export default ParentCreate;
