import React, { useRef, useEffect } from 'react';
import axios from 'axios';
import { api } from '../Settings';
import { useTable, useSortBy, useGlobalFilter, usePagination } from 'react-table';
import { GlobalFilter } from './GlobalFilter';
import { updateCountries } from '../services/updateCountries';
import { MdDelete } from 'react-icons/md';
import Flags from 'country-flag-icons/react/3x2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { BiLoaderAlt } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import { ArrowLongDownIcon, ArrowLongUpIcon, FunnelIcon } from '@heroicons/react/24/outline';

// Let's add a fetchData method to our Table component that will be used to fetch
// new data when pagination state changes
// We can also add a loading state to let our table know it's loading new data
function Table({ columns, data, fetchData, loading, pageCount: controlledPageCount }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    // Get the state from the instance
    state: { globalFilter, pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 }, // Pass our hoisted table state
      //    manualPagination: true, // Tell the usePagination
      // hook that we'll handle our own data fetching
      // This means we'll also have to provide our own
      // pageCount.
      pageCount: controlledPageCount,
    },

    useGlobalFilter,
    useSortBy,
    usePagination,
  );
  const { t, i18n } = useTranslation();

  // Listen for changes in pagination and use the state to fetch our new data
  React.useEffect(() => {
    fetchData({ pageIndex, pageSize });
  }, [fetchData, pageIndex, pageSize]);

  // Render the UI for your table
  return (
    <>
      <GlobalFilter globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
      <div className="text-sm bg-white shadow-md rounded-lg mb-4">
        {/*  <pre>
          <code>
            {JSON.stringify(
              {
                pageIndex,
                pageSize,
                pageCount,
                canNextPage,
                canPreviousPage,
              },
              null,
              2
            )}
          </code>
        </pre> */}

        <table {...getTableProps()} className=" w-full">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={`py-4 px-4 ${index === headerGroup.headers.length - 1 ? 'text-right' : 'text-left'}`}
                  >
                    {column.render('Header')}
                    {!column.disableSortBy && (
                      <span>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <ArrowLongDownIcon className="h-3 w-3 inline ml-1" />
                          ) : (
                            <ArrowLongUpIcon className="h-3 w-3 inline ml-1" />
                          )
                        ) : (
                          <FunnelIcon className="h-3 w-3 inline ml-1" />
                        )}
                      </span>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.length > 0 ? (
              page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    className={`border border-b border-black/10 ${i % 2 === 1 && 'bg-black/5'}`}
                  >
                    {row.cells.map((cell, index) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          className={`py-2 px-4 text-black/50 ${index === row.cells.length - 1 ? 'text-right' : ''}`}
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })
            ) : (
              <tr className="hover">
                <td colSpan={10000} className="text-center py-4 border-b border-black/10">
                  {t('table.no-data')}
                </td>
              </tr>
            )}
            {/* <tr>
              {loading ? (
                // Use our custom loading state to show a loading indicator
                <td colSpan="10000">Loading...</td>
              ) : (
                <td colSpan="10000">
                  Showing {page.length} of ~{controlledPageCount * pageSize}{' '}
                  results
                </td>
              )}
            </tr> */}
          </tbody>
        </table>
        {/* 
          Pagination can be built however you'd like. 
          This is just a very basic UI implementation:
        */}

        <div className="flex items-center py-2 px-4 text-xs">
          <div className="item-count">
            {loading ? (
              // Use our custom loading state to show a loading indicator
              <>{t('general.loading')}</>
            ) : (
              <>
                {t('table.showing')} {page.length} of ~{controlledPageCount * pageSize} {t('table.results')}
              </>
            )}
          </div>
          <div className="pagination ml-auto">
            <button
              className={`p-1 w-6 rounded bg-primary text-white ${!canPreviousPage && 'opacity-50'}`}
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              {'<<'}
            </button>{' '}
            <button
              className={`p-1  w-6 rounded bg-primary text-white ${!canPreviousPage && 'opacity-50'}`}
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              {'<'}
            </button>{' '}
            <button
              className={`p-1 w-6  rounded bg-primary text-white ${!canNextPage && 'opacity-50'}`}
              onClick={() => nextPage()}
              disabled={!canNextPage}
            >
              {'>'}
            </button>{' '}
            <button
              className={`p-1 w-6  rounded bg-primary text-white ${!canNextPage && 'opacity-50'}`}
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {'>>'}
            </button>{' '}
            <span className="ml-2">
              {t('table.page')}{' '}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>{' '}
            </span>
            <span>
              | {t('table.go-to-page')}:{' '}
              <input
                type="number"
                defaultValue={pageIndex + 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(page);
                }}
                className="border border-black/10 rounded w-12 py-1 pl-2 outline-none"
              />
            </span>{' '}
            <select
              className="border border-black/10 rounded bg-white  py-1 px-2"
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[25, 50, 100].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {t('table.show')} {pageSize}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </>
  );
}

function App({ countryUpdate }) {
  const infoRef = useRef();
  const { t, i18n } = useTranslation();

  const columns = [
    /*  {
      Header: "",
      accessor: "id",
      Cell: () => {
        return  <div className='id'>
      <div className="mb-[0.125rem] block min-h-[1.5rem] pl-[1.5rem]">
            <input
              className="relative float-left mt-[0.15rem] mr-[6px] -ml-[1.5rem] h-[1.125rem] w-[1.125rem] appearance-none rounded-[0.25rem] border-[0.125rem] border-solid border-[rgba(0,0,0,0.25)] bg-white outline-none before:pointer-events-none before:absolute before:h-[0.875rem] before:w-[0.875rem] before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] checked:border-primary checked:bg-primary checked:before:opacity-[0.16] checked:after:absolute checked:after:ml-[0.25rem] checked:after:-mt-px checked:after:block checked:after:h-[0.8125rem] checked:after:w-[0.375rem] checked:after:rotate-45 checked:after:border-[0.125rem] checked:after:border-t-0 checked:after:border-l-0 checked:after:border-solid checked:after:border-white checked:after:bg-transparent checked:after:content-[''] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:transition-[border-color_0.2s] focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-[0.875rem] focus:after:w-[0.875rem] focus:after:rounded-[0.125rem] focus:after:bg-white focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:after:ml-[0.25rem] checked:focus:after:-mt-px checked:focus:after:h-[0.8125rem] checked:focus:after:w-[0.375rem] checked:focus:after:rotate-45 checked:focus:after:rounded-none checked:focus:after:border-[0.125rem] checked:focus:after:border-t-0 checked:focus:after:border-l-0 checked:focus:after:border-solid checked:focus:after:border-white checked:focus:after:bg-transparent"
              type="checkbox"
              value=""/>
          </div>
        </div>
      },
    }, */
    {
      Header: t('table.columns.flag'),
      accessor: 'id',
      Cell: ({ row }) => {
        const Flag = Flags[row.original.alpha_2.toUpperCase()];
        const title = row.original.name;
        if (Flag) {
          return (
            <div className="flex">
              <Flag title={title} className="w-6 h-auto" />
            </div>
          );
        }
      },
      disableSortBy: true,
    },
    {
      Header: t('table.columns.name'),
      accessor: 'name',
      Cell: ({ value }) => {
        return <span className="text-body" dangerouslySetInnerHTML={{ __html: value }} />;
      },
    },
    {
      Header: t('table.columns.code'),
      accessor: 'alpha_2',
    },

    {
      Header: '',
      accessor: 'actions',
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <div className="actions text-xl flex items-center justify-end">
            <MdDelete onClick={() => confirmDelete(row?.original.id)} className="text-icon cursor-pointer ml-4" />
          </div>
        );
      },
    },
  ];

  // We'll start our table without any data
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  //const [reload, setReload] = React.useState(false)
  const [currId, setId] = React.useState(null);
  const [pageCount, setPageCount] = React.useState(0);
  const fetchIdRef = React.useRef(0);

  const fetchData = React.useCallback(
    ({ pageSize, pageIndex }) => {
      // This will get called when the table needs new data
      // You could fetch your data from literally anywhere,
      // even a server. But for this example, we'll just fake it.

      // Set the loading state
      setLoading(true);

      axios
        .get(api.getCountries, { params: { status: 'active' } })
        .then((response) => {
          // console.log(response);
          const startRow = pageSize * pageIndex;
          const endRow = startRow + pageSize;

          //const dataSet = response.data;
          const dataSet = response.data.data;
          setData(dataSet.slice(startRow, endRow));

          // Your server could send back total page count.
          // For now we'll just fake it, too
          // setPageCount(Math.ceil(dataSet.length / pageSize))

          setPageCount(Math.ceil(response.data.data.length / pageSize));
          //console.log(Math.ceil(response.data.data.length / pageSize));

          setLoading(false);
          // setReload(false)
        })
        .catch(function (error) {
          console.log(error);
          //that.setState({ isLoading: false, isError: true  })
        });
    },
    [currId, countryUpdate],
  );

  const confirmDelete = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="text-center shadow-lg p-4 rounded-lg bg-white md:min-w-[450px] min-w-[300px]">
            <h1 className="text-2xl font-bold">{t('general.confirm_alert_title')}</h1>
            <p className="text-body mb-4">{t('general.remove_country_alert_message')}</p>
            <button
              onClick={onClose}
              className="btn border border-primary py-1.5 px-5 rounded-md text-primary  hover:bg-primary hover:text-white cursor-pointer mx-2"
            >
              Cancel
            </button>
            <button
              className="btn border border-error py-1.5 px-5 rounded-md text-error  hover:bg-error hover:text-white cursor-pointer mx-2"
              onClick={() => {
                infoRef.current = toast.info('Processing your request...', {
                  progress: 100,
                  closeButton: false,
                  autoClose: false,
                  icon: <BiLoaderAlt className="animate-spin h-6 w-6 text-primary" />,
                });
                handleClickDelete(id);
                onClose();
              }}
            >
              {t('general.yes')}
            </button>
          </div>
        );
      },
    });
  };

  const handleClickDelete = async (id) => {
    const res = await updateCountries([id], 'Deactivate');
    toast.dismiss(infoRef.current);
    if (res) {
      toast.success(res.message, {
        autoClose: 3000,
      });
    } else {
      toast.error(`Failed to remove country: ${res.message}`);
    }

    setId(id);
  };

  return (
    <>
      <Table columns={columns} data={data} fetchData={fetchData} loading={loading} pageCount={pageCount} />
      <ToastContainer />
    </>
  );
}

export default App;
