import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { getTestDatatable } from '../services/getTests';
import { getCountries } from '../services/getCountries';
import { getBatches } from '../services/getBatches';
import TablePagination from './TablePagination';
import { BsEyeFill } from 'react-icons/bs';
import Flags from 'country-flag-icons/react/3x2';
import { Link } from 'react-router-dom';
import { BiWorld } from 'react-icons/bi';
import { FaWaveSquare } from 'react-icons/fa';
import { MdContentCopy, MdOutlineGroups, MdOutlineSchool, MdPayment } from 'react-icons/md';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as dayjs from 'dayjs';

function TestDatatable() {
  const [data, setData] = useState([]);
  const [countries, setCountries] = useState([]);
  const [batches, setBatches] = useState([]);
  const [checkboxValues, setCheckboxValues] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [totalRow, setTotalRow] = useState(0);

  const fetchData = useCallback(async (pageSize, pageIndex, search, order, filters) => {
    setLoading(true);
    const queryOptions = {
      page: pageIndex + 1,
      per_page: pageSize,
      search: search,
      order: order,
      filters: filters,
    };
    const items = await getTestDatatable(queryOptions);
    const data = items && items.data ? items.data : [];

    console.log('call to get data');
    setData(data);
    setPageCount(Math.ceil(items.total / items.per_page));
    setTotalRow(items.total);
    setLoading(false);
  }, []);

  useEffect(() => {
    const fetchCountryData = async () => {
      const countryList = await getCountries();
      setCountries(countryList);
    };
    fetchCountryData().catch(console.error);

    const fetchBatchData = async () => {
      const batchList = await getBatches();
      const items = [];
      batchList?.map((item) => {
        items.push({ id: item.value, name: item.label });
      });

      setBatches(items);
    };
    fetchBatchData().catch(console.error);
  }, []);

  const checkCb = (val) => {
    return checkboxValues?.includes(val);
  };

  const selectCallBack = (checked) => {
    console.log(checked, data);
    if (checked) {
      const checkboxValues = data?.map((a) => a.test_id);
      setCheckboxValues(checkboxValues);
    } else {
      setCheckboxValues([]);
    }
    // return checkboxValues?.includes(val);
  };

  const selectItems = (event, id) => {
    if (event.target.checked) {
      setCheckboxValues((checkboxValues) => [...checkboxValues, id]);
    } else {
      setCheckboxValues(
        checkboxValues.filter(function (val) {
          return val !== id;
        }),
      );
    }
  };

  const columns = useMemo(
    () => [
      // {
      //   Header: '',
      //   accessor: 'id',
      //   class: 'md:pr-0',
      //   selectCb: 'test_id',
      //   Cell: ({ row }) => {
      //     return (
      //       <div className="id flex items-center">
      //         <div className="mb-[0.125rem] block min-h-[1.5rem] pl-[1.5rem]">
      //           <input
      //             className="relative float-left mt-[0.15rem] mr-[6px] -ml-[1.5rem] h-[1.125rem] w-[1.125rem] appearance-none rounded-[0.25rem] border-[0.125rem] border-solid border-[rgba(0,0,0,0.25)] bg-white outline-none before:pointer-events-none before:absolute before:h-[0.875rem] before:w-[0.875rem] before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] checked:border-primary checked:bg-primary checked:before:opacity-[0.16] checked:after:absolute checked:after:ml-[0.25rem] checked:after:-mt-px checked:after:block checked:after:h-[0.8125rem] checked:after:w-[0.375rem] checked:after:rotate-45 checked:after:border-[0.125rem] checked:after:border-t-0 checked:after:border-l-0 checked:after:border-solid checked:after:border-white checked:after:bg-transparent checked:after:content-[''] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:transition-[border-color_0.2s] focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-[0.875rem] focus:after:w-[0.875rem] focus:after:rounded-[0.125rem] focus:after:bg-white focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:after:ml-[0.25rem] checked:focus:after:-mt-px checked:focus:after:h-[0.8125rem] checked:focus:after:w-[0.375rem] checked:focus:after:rotate-45 checked:focus:after:rounded-none checked:focus:after:border-[0.125rem] checked:focus:after:border-t-0 checked:focus:after:border-l-0 checked:focus:after:border-solid checked:focus:after:border-white checked:focus:after:bg-transparent"
      //             type="checkbox"
      //             value={row.original.test_id}
      //             defaultChecked={checkCb(row.original.test_id)}
      //             onChange={(e) => selectItems(e, row.original.test_id)}
      //           />
      //         </div>
      //       </div>
      //     );
      //   },
      //   disableSortBy: true,
      // },
      {
        Header: 'Test ID',
        accessor: 'testid',
        class: 'hidden md:table-cell',
        Cell: ({ row }) => {
          return (
            <div className="id flex items-center">
              <span
                className="text-body italic bg-primary/10 rounded-xl px-3 py-1 text-xs inline-flex items-center cursor-pointer focus:bg-primary/40 hover:bg-primary/20"
                title="Copy to clipboard"
                onClick={() => {
                  navigator.clipboard.writeText(row.original.test_id);
                  toast.info('Test ID Copied to Clipboard', {
                    position: 'bottom-center',
                    hideProgressBar: true,
                    theme: 'dark',
                    autoClose: 1000,
                    theme: 'colored',
                  });
                }}
              >
                <span className="max-w-[80px] truncate">{row.original.test_id}</span>
                <MdContentCopy className="text-primary text-sm  ml-1" />
              </span>
            </div>
          );
        },
        disableSortBy: true,
      },
      {
        Header: 'Student Name',
        accessor: 'child_name',
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <>
              <span className="text-body block font-semibold mb-0.5 ">{row.original.child_name}</span>
              <span
                className=" text-body italic bg-primary/10 rounded-xl px-3 py-1 text-xs inline-flex items-center cursor-pointer focus:bg-primary/40 hover:bg-primary/20"
                title="Copy to clipboard"
                onClick={() => {
                  navigator.clipboard.writeText(row.original.child_id);
                  toast.info('Student ID Copied to Clipboard', {
                    position: 'bottom-center',
                    hideProgressBar: true,
                    theme: 'dark',
                    autoClose: 1000,
                    theme: 'colored',
                  });
                }}
              >
                <span className="max-w-[80px] truncate">{row.original.child_id}</span>
                <MdContentCopy className="text-primary text-sm  ml-1" />
              </span>
            </>
          );
        },
      },
      {
        Header: 'School Name',
        accessor: 'school_name',
        class: 'hidden md:table-cell',
      },
      {
        Header: 'Coach Name',
        accessor: 'coach_name',
        class: 'hidden md:table-cell',
      },
      {
        Header: 'Added Date',
        accessor: 'created_at',
        class: 'hidden md:table-cell',
        Cell: ({ value }) => {
          return dayjs(value).format('DD MMM YYYY');
        },
      },

      {
        Header: 'Su Approved',
        accessor: 'su_approved',
        class: 'hidden md:table-cell',
      },
      {
        Header: 'Payment',
        accessor: 'paid_status',
        //class:"hidden md:table-cell",
        Cell: ({ value }) => {
          return (
            <>
              {value?.toLowerCase() === 'success' ? (
                <span className="text-green-500">Paid</span>
              ) : value === 'SPONSORED' ? (
                <span className="text-green-500">Sponsored</span>
              ) : (
                <span className="text-orange-500">Unpaid</span>
              )}
            </>
          );
        },
      },
      {
        Header: 'Test Status',
        accessor: 'status',
        class: 'hidden md:table-cell',
        Cell: ({ value }) => {
          return (
            <>
              {value === 'completed' ? (
                <span className="text-green-500">Completed</span>
              ) : (
                <span className="text-orange-500">Pending</span>
              )}
            </>
          );
        },
      },
      {
        Header: '',
        accessor: 'actions',
        disableSortBy: true,
        Cell: ({ row }) => {
          //query: {paramobj: JSON.stringify(row)}
          // console.warn(JSON.stringify(row));
          //  console.warn(row);

          return (
            <div className="flex gap-2 text-xl items-center justify-end text-icon">
              {/*  <div className="flex justify-center">
                                <div>
                                <input
                                    className="mt-[0.3rem] mr-2 h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-[rgba(0,0,0,0.25)] outline-none before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-icon after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary/20 checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary/30 checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s]"
                                    type="checkbox"
                                    role="switch"
                                    />
                                </div>
                            </div> */}
              {/*   <button className="btn btn-xs btn-info">
                                <MdDelete className='cursor-pointer'/>
                            </button> */}
              <Link
                to={{ pathname: `/test-management/${row.original.test_id}`, query: row.original }}
                state={row.original}
                className="btn btn-xs btn-error hover:text-primary"
              >
                <BsEyeFill className="cursor-pointer" />
              </Link>
            </div>
          );
        },
      },
    ],
    [countries, checkboxValues],
  );

  const filters = useMemo(
    () => [
      {
        label: 'Status',
        key: 'status',
        value: null,
        selected: null,
        icon: <FaWaveSquare />,
        options: [
          {
            id: 'completed',
            name: 'Completed',
          },
          {
            id: 'pending',
            name: 'Pending',
          },
        ],
      },
      {
        label: 'Payment',
        key: 'payment',
        value: null,
        selected: null,
        icon: <MdPayment />,
        options: [
          {
            id: 'paid',
            name: 'Paid',
          },
          {
            id: 'unpaid',
            name: 'Unpaid',
          },
        ],
      },
      {
        label: 'Batch',
        key: 'batch',
        value: null,
        selected: null,
        icon: <MdOutlineGroups />,
        options: batches,
      },
    ],
    [batches],
  );

  return (
    <section className="relative">
      <TablePagination
        selectCallBack={selectCallBack}
        filters={filters}
        selectedIds={checkboxValues}
        countries={countries}
        columns={columns}
        data={data}
        fetchData={fetchData}
        loading={loading}
        pageCount={pageCount}
        totalRow={totalRow}
      />
      <ToastContainer />
    </section>
  );
}

export default TestDatatable;
