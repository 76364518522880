import axios from 'axios';
import { api } from '../Settings';

export const getTest = async (id) => {
  try {
    const request = await axios(api.getTest, { params: { test_id: id } }).catch((err) => {
      if (err.response && err.response.status === 401) {
        window.location.href = '/logout';
      }
    });

    const response = request.data;
    return response;
  } catch (error) {
    console.log(`getTest error: ${error}`);
    return false;
  }
};
