import React, { Component, createRef } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Lang, Drawer } from './../components';
import Footer from './../components/Footer';
import ApiError from './../components/ApiError';
import Loader from './../components/Loader';
import { getCountries } from '../services/getCountries';
import Breadcrumb from './../components/Breadcrumb';
import { FiMapPin } from 'react-icons/fi';
import { MdBlock, MdDelete, MdDone, MdOutlineEdit } from 'react-icons/md';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { getProfile } from '../services/getProfile';

const userData = JSON.parse(localStorage.getItem('_userData'));
const USER_TYPE = userData && userData.data.user_type;
const USER_ID = USER_TYPE && userData[USER_TYPE + '_details'] && userData[USER_TYPE + '_details'][USER_TYPE + '_id'];

function withParams(Component) {
  return (props) => <Component {...props} navigation={useNavigate()} location={useLocation()} params={useParams()} />;
}

class MyProfile extends Component {
  constructor(props) {
    super(props);
    this.infoRef = createRef();
    const { state } = this.props.location;
    this.state = {
      active: true,
      isLoading: false,
      isError: false,
      data: state,
      countryList: null,
    };

    this.getData(USER_TYPE);
    this.getCountryData();
  }

  async getData(id) {
    const data = await getProfile(id);
    this.setState({ data: data.data });
  }

  async getCountryData() {
    const countryList = await getCountries();
    this.setState({ countryList: countryList });
  }

  setCountry = (id) => {
    const countryObj = this.state.countryList?.filter((country) => country.id === id);
    if (countryObj?.length === 1) {
      return countryObj[0].name;
    }
  };

  handleCallback = (drawer) => {
    this.setState({ active: drawer.show });
  };

  render() {
    const { data, isLoading, isError } = this.state;
    console.log(data);
    return (
      // <HelmetProvider>
      //   <div>
      //   <Helmet>
      //     <title>SportUnleash - My Profile</title>
      //   </Helmet>
      //   <div className="container mx-auto lg:flex  bg-bg">
      //     <Drawer show={this.state.active} drawerCallback={this.handleCallback} />
      //     <Lang />
      //     <div className="w-full md:w-[calc(100%-84px)] 2xl:w-full md:ml-[84px] 2xl:ml-0 md:p-4 lg:p-8 min-h-screen relative pb-[90px] lg:pb-[100px] md:pb-[100px]">
      //         {isLoading ? <Loader /> :
      //          <>
      //           <Breadcrumb />
      //           <div className="flex  items-center">
      //               <h1 className="text-xl md:text-3xl mb-8 font-bold italic">My Profile</h1>
      //           </div>
      //           <div className="flex  items-center justify-between mb-4">
      //               <h2 className="text-2xl md:text-3xl">{data?.info?.name}</h2>
      //               <div className="gap-x-2 flex text-sm">
      //                 <Link to="/my-profile/edit" className={`flex items-center btn border border-primary py-1.5 px-3 rounded-md text-primary  hover:bg-primary hover:text-white cursor-pointer`} >
      //                  <MdOutlineEdit className="w-5 h-5 mr-1"/> Edit Profile
      //                 </Link>
      //               </div>
      //           </div>
      //           {
      //             data?.info?.country &&
      //             <div className="text-lg flex items-center">
      //               <FiMapPin className="mr-4 text-icon"/>{this.setCountry(data?.info?.country)}
      //             </div>
      //           }

      //           <div className="mt-8 text-sm relative flex items-center">
      //              <h3 className="pr-2 text-base text-black/60">About</h3>
      //              <span className="w-full  border-t border-body/10"></span>
      //           </div>

      //           <div className="text-sm text-black mt-8">
      //             {
      //               data?.fields?.map((field, index) => {

      //                 return (
      //                 field.value &&
      //                 <div className="flex mb-4" key={index}>
      //                   <span className="min-w-[100px] md:min-w-150">{field.label} : </span>
      //                   <span className="text-body">
      //                     { field.name === "country" ? ( this.setCountry(field.value) ) : ( field.value ) }
      //                   </span>
      //                 </div>
      //                 );
      //               })
      //             }

      //           </div>

      //        </>
      //         }
      //         {isError && <ApiError />}

      //         <Footer />
      //     </div>
      //     <ToastContainer />
      //   </div>

      //   </div>
      //   </HelmetProvider>

      <>
        <Breadcrumb />
        <div className="flex  items-center">
          <h1 className="text-xl md:text-3xl mb-8 font-bold italic">My Profile</h1>
        </div>
        <div className="flex  items-center justify-between mb-4">
          <h2 className="text-2xl md:text-3xl">{data?.info?.name}</h2>
          <div className="gap-x-2 flex text-sm">
            <Link
              to="/my-profile/edit"
              className={`flex items-center btn border border-primary py-1.5 px-3 rounded-md text-primary  hover:bg-primary hover:text-white cursor-pointer`}
            >
              <MdOutlineEdit className="w-5 h-5 mr-1" /> Edit Profile
            </Link>
          </div>
        </div>
        {data?.info?.country && (
          <div className="text-lg flex items-center">
            <FiMapPin className="mr-4 text-icon" />
            {this.setCountry(data?.info?.country)}
          </div>
        )}

        <div className="mt-8 text-sm relative flex items-center">
          <h3 className="pr-2 text-base text-black/60">About</h3>
          <span className="w-full  border-t border-body/10"></span>
        </div>

        <div className="text-sm text-black mt-8">
          {data?.fields?.map((field, index) => {
            return (
              field.value && (
                <div className="flex mb-4" key={index}>
                  <span className="min-w-[100px] md:min-w-150">{field.label} : </span>
                  <span className="text-body">
                    {field.name === 'country' ? this.setCountry(field.value) : field.value}
                  </span>
                </div>
              )
            );
          })}
        </div>
      </>
    );
  }
}

export default withParams(MyProfile);
