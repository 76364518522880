import React, { Component, createRef } from 'react';
import Field from './Field';
import axios from 'axios';
import { Link, useLocation } from 'react-router-dom';
import { api } from '../Settings';
import Loader from './../components/Loader';
import { BiLoaderAlt } from 'react-icons/bi';
import { getCountries } from '../services/getCountries';
import { getProfile } from '../services/getProfile';

const userData = JSON.parse(localStorage.getItem('_userData'));
const USER_TYPE = userData && userData.data.user_type;

function withParams(Component) {
  return (props) => <Component {...props} location={useLocation()} />;
}

class Form extends Component {
  state = {
    fieldsStatic: [
      {
        name: 'user_type',
        type: 'hidden',
        value: USER_TYPE,
        validation: {
          required: true,
        },
        static: true,
        valid: true,
      },
      {
        type: 'section',
        value: 'Personal Details',
        valid: true,
        touched: true,
        colspan: 'col-span-2',
      },
      {
        name: 'first_name',
        label: 'First Name',
        type: 'text',
        value: '',
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
      },
      {
        name: 'last_name',
        label: 'Last Name',
        type: 'text',
        value: '',
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
      },
      {
        name: 'date_of_birth',
        label: 'Date of Birth',
        type: 'date',
        value: '',
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
      },
      {
        name: 'mobile_number',
        label: 'Contact Number',
        type: 'tel',
        value: '',
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
      },
      {
        name: 'address_line1',
        label: 'Address Line 1',
        type: 'text',
        value: '',
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
      },
      {
        name: 'address_line2',
        label: 'Address Line 2',
        type: 'text',
        value: '',
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
      },
      {
        name: 'city',
        label: 'City',
        type: 'text',
        value: '',
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
      },
      {
        name: 'state',
        label: 'State (Optional)',
        type: 'text',
        value: '',
        validation: {
          required: false,
        },
        valid: true,
        touched: false,
      },
      {
        name: 'province',
        label: 'Province (Optional)',
        type: 'text',
        value: '',
        validation: {
          required: false,
        },
        valid: true,
        touched: false,
      },
      {
        name: 'post_code',
        label: 'Postal Code',
        type: 'text',
        value: '',
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
      },
      /*    {
                name: 'email',
                label: 'Email ',
                type: 'email',
                value: '',
                validation: {
                    required: true,
                    pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                },
                valid: false,
                touched: false
            },
            {
                name: 'country',
                label: 'Country',
                type: 'country',
                value: '',
                validation: {
                    required: true
                },
                touched: false
            },
            {
                name: 'password',
                label: 'Password',
                type: 'password',
                value: '',
                validation: {
                    //pattern: /^(?=.*\d)(?=.*[a-zA-Z]).{8,}$/,
                    required: true
                },
                valid: false,
                touched: false,
                errorMessage: 'Use at least 8 characters, Password should contain numbers and letters.'
            },
            {
                name: 'password_confirmation',
                label: 'Confirm Password',
                type: 'password',
                value: '',
                validation: {
                    //pattern: /^(?=.*\d)(?=.*[a-zA-Z]).{8,}$/,
                    required: true
                },
                valid: false,
                touched: false
            }, */
    ],
    fields: [],
    settings: null,
    isSubmitting: false,
    isLoading: false,
    formValid: false,
    successMessage: '',
    apiError: '',
    token: null,
    countries: [],
    editEnable: false,
  };

  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    const data = await getProfile(USER_TYPE);
    const countryList = await getCountries();

    if (data.data.fields) {
      /* const { state } = this.props.location;
            console.log(state); */

      data.data.fields.map((field) => {
        if (field.value) {
          //field.touched  = true;
          field.valid = true;
        }

        if (field.name === 'country') {
          field.options = countryList;
        }

        console.log(field);
      });

      console.log(data.data.fields);

      this.setState({
        fields: data.data.fields,
      });
    }
  }

  fieldChange = (event, field, index) => {
    const updatedField = { ...field };
    updatedField.value = event && event.target.value;

    if (field.type === 'country' || field.type === 'tel') {
      updatedField.value = field.value;
    }

    updatedField.valid = this.checkValidity(updatedField);

    const updatedFields = [...this.state.fields];
    updatedFields.splice(index, 1, updatedField);
    let formValid = true;
    for (let field of updatedFields) {
      if (!field.valid) {
        formValid = false;
      }
    }
    this.setState({
      fields: updatedFields,
      formValid: formValid,
      editEnable: true,
    });
  };

  checkValidity = (field) => {
    const rules = field.validation;
    const value = field.value;
    let isValid = true;

    if (!rules) {
      return true;
    }
    if (rules.required) {
      isValid = value?.toString().trim() !== '' && isValid;
    }
    if (rules.minLength) {
      isValid = value.length >= rules.minLength && isValid;
    }
    if (rules.maxLength) {
      isValid = value.length <= rules.maxLength && isValid;
    }
    if (rules.pattern) {
      isValid = rules.pattern.test(value) && isValid;
    }
    if (field.name === 'password_confirmation') {
      const pwdItem = this.state.fields.filter((field, index) => field.name === 'password');
      let currPwd = pwdItem[0].value;

      //console.log(field)
      isValid = value === currPwd && isValid;
    }

    /* if (field.name === 'password') {
          
            const pwdItem = this.state.fields.filter((field, index) => field.name === 'password_confirmation');

            console.log(pwdItem[0]);
            this.checkValidity(pwdItem[0])
        } */

    console.log(field.name, isValid, field);

    return isValid;
  };
  fieldBlur(event, field, index) {
    if (field.touched) {
      return;
    }
    const updatedField = { ...field };
    updatedField.touched = true;
    updatedField.valid = this.checkValidity(updatedField);
    const updatedFields = [...this.state.fields];
    updatedFields.splice(index, 1, updatedField);
    this.setState({
      fields: updatedFields,
    });
  }
  onSubmit = (event) => {
    console.log(this.state.formValid);

    this.state.fields.map((field, index) => {
      if (!this.checkValidity(field)) {
        console.log(field);
        this.setState({
          formValid: false,
        });
      }
    });

    console.log(this.state.formValid);

    if (this.state.formValid) {
      this.sendData();
    } else {
      this.state.fields.map((field, index) => {
        this.checkValidity(field);
        console.log(this.checkValidity(field));
        field.touched = true;
      });

      this.setState({ fields: this.state.fields });
    }
    event.preventDefault();
  };

  clearForm = () => {
    this.state.fields.map((field, index) => {
      if (!field.static) {
        field.value = '';
      }
    });
  };

  sendData = async () => {
    const dataObj = {
      user_type: USER_TYPE,
      //  user_id: USER_ID
    };

    this.state.fields.map((field, index) => {
      if (field.name && field.value) {
        dataObj[field.name] = field.value;
      }
    });

    let that = this;
    that.setState({ isSubmitting: true });

    axios
      .post(api.profileEdit, dataObj)
      .then(function (response) {
        let data = response.data;
        if (data.success) {
          that.setState({ successMessage: data.message });
          //that.clearForm();
        } else {
          that.setState({ apiError: data.message });
        }

        that.setState({ isSubmitting: false });
      })
      .catch(function (error) {
        that.setState({ isSubmitting: false, apiError: 'Unable to connect with the api.' });
        console.log(error);
        //that.setState({  successMessage: 'Congratulations, your account has been successfully created.' });
      });
  };

  render() {
    const { isLoading } = this.state;
    return (
      <>
        <div className="mx-auto mt-4">
          <div className="w-full">
            <form onSubmit={(event) => this.onSubmit(event)}>
              <div className="md:grid md:grid-cols-2 gap-x-8 gap-y-4">
                {this.state.fields.map((field, index) => {
                  return (
                    <Field
                      key={index}
                      fieldConfig={field}
                      blured={(event) => this.fieldBlur(event, field, index)}
                      changed={(event) => this.fieldChange(event, field, index)}
                    />
                  );
                })}
              </div>

              <div className="">
                {isLoading ? <Loader /> : <></>}

                <>
                  {!this.state.isSubmitting && (
                    <div className="flex justify-end items-center mt-8 mb-8 gap-x-4">
                      {this.state.successMessage ? (
                        <span className="text-success text-base italic">{this.state.successMessage} </span>
                      ) : (
                        this.state.apiError && (
                          <span className="text-error text-base italic">{this.state.apiError} </span>
                        )
                      )}

                      <button
                        type="submit"
                        className={`inline-block btn bg-primary shadow-md py-3 px-5 rounded-md text-white hover:shadow-lg hover:opacity-80 ${
                          isLoading || (!this.state.editEnable && 'opacity-50 pointer-events-none')
                        }`}
                        disabled={!this.state.editEnable}
                      >
                        Update
                      </button>
                      <Link
                        to={'/my-profile'}
                        className={`inline-block btn border border-primary bg-transparent shadow-md py-3 px-5 rounded-md text-primary hover:shadow-lg hover:opacity-80 ${
                          isLoading && 'opacity-50 pointer-events-none'
                        }`}
                        data-disabled={!this.state.formValid}
                      >
                        Cancel
                      </Link>
                    </div>
                  )}
                  {this.state.isSubmitting && (
                    <div className="flex justify-end items-start mt-8 gap-x-4">
                      <button
                        type="button"
                        className="btn bg-primary py-3 px-5 rounded-md text-white shadow-lg opacity-80 flex items-center justify-center cursor-default"
                      >
                        <BiLoaderAlt className="animate-spin h-5 w-5 mr-2" /> Submitting...
                      </button>
                    </div>
                  )}
                </>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
}

export default withParams(Form);
