import React, { Component } from 'react';
import logo from './../assets/logo.png';
import axios from 'axios';
import { useNavigate, NavLink, useLocation } from 'react-router-dom';
import { BiLoaderAlt } from 'react-icons/bi';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { api } from '../Settings';
import OtpInput from 'react-otp-input';

function withParams(Component) {
  return (props) => <Component {...props} navigation={useNavigate()} location={useLocation()} />;
}

class ResetPasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      otpError: '',
      apiError: '',
      successMessage: '',
      otp: '',
      isLoading: false,
      email: null,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.validate = this.validate.bind(this);
    this.setOtp = this.setOtp.bind(this);
  }

  componentDidMount() {
    const { state } = this.props.location;
    const { email } = state || {};

    if (email) {
      this.state.email = email;
    }
  }

  handleChange(event) {
    const target = event.target;
    const val = target.value ? target.value : '';
    const value = target.type === 'checkbox' ? target.checked : val;
    const name = target.name;
    this.setState({ [name]: value });
    this.setState({ apiError: '', otpError: '', successMessage: '' });
  }

  validate() {
    let otpError = '';
    let apiError = '';

    this.setState({ apiError: '' });

    // const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    console.log(this.state.otp.length);
    if (!this.state.otp || this.state.otp.length !== 5) {
      otpError = 'OTP is Invalid ';
    }

    this.setState({ otpError });

    if (otpError) {
      return false;
    }
    return true;
  }

  handleSubmit(event) {
    if (this.validate()) {
      const { navigation } = this.props;
      const that = this;
      const email = this.state.email;

      that.setState({ isLoading: true });

      console.log(this.state.otp);

      axios
        .post(api.verifyOtp, {
          otp: this.state.otp,
          email: email,
        })
        .then(function (response) {
          let data = response.data;
          if (data.success) {
            navigation('/new-password', { state: { email } });
            //  navigation(0);

            that.setState({ successMessage: data.message });
          } else {
            that.setState({ apiError: data.message });
          }

          that.setState({ isLoading: false });
        })
        .catch(function (error) {
          const mzg = error.response?.data?.message ? error.response?.data?.message : 'Unable to connect with the api.';
          that.setState({ isLoading: false, apiError: mzg });

          // that.setState({  successMessage: 'Password reset email successfully sent. Please check your emails and follow instructions.' });
        });
    }

    event.preventDefault();
  }

  setOtp(val) {
    //  console.log(val);
    this.setState({ otp: val });
  }

  render() {
    const { otp } = this.state;

    return (
      <>
        <div className="login-form mx-auto w-10/12 md:w-[360px]">
          <div className="w-full text-left text-base text-gray">
            <form method="POST" className="bg-white" onSubmit={this.handleSubmit}>
              <div className="text-center mb-8 ">
                <img className="logo mx-auto mb-6" width="223" height="auto" src={logo} />
              </div>

              {!this.state.successMessage && (
                <>
                  <div className="mb-8 text-center lg:text-left">
                    <h1 className="text-2xl md:text-3xl font-normal text-heading">Reset Password</h1>
                    <p>Enter the code from the email we sent to given email.</p>
                  </div>
                  <div className="mb-2 md:mb-4">
                    <label className="block text-gray-700  font-semibold mb-2" htmlFor="email">
                      Enter the code
                    </label>

                    <OtpInput
                      value={otp}
                      onChange={this.setOtp}
                      numInputs={5}
                      containerStyle={'otp-wrp mt-4 grid-cols-5 gap-2 grid'}
                      renderSeparator={''}
                      renderInput={(props) => (
                        <input {...props} className="otp-input w-8 border border-secondary p-0 rounded text-lg" />
                      )}
                    />

                    {/* 
                            <input  onChange={this.handleChange} className="shadow appearance-none border border-secondary rounded w-full py-5 px-5 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" value={this.state.email || ''}    name="email" type="email" placeholder="yourname@company.com"></input> */}
                    <span className="text-red-500 text-xs italic">{this.state.otpError}</span>
                  </div>
                </>
              )}

              {this.state.successMessage && (
                <span className="text-primary text-base italic mt-2 mb-8 block text-center">
                  {this.state.successMessage}
                </span>
              )}

              <div className="text-center">
                {!this.state.successMessage && (
                  <>
                    {!this.state.isLoading && (
                      <button
                        type="submit"
                        className="inline-block w-full mt-2 btn bg-primary shadow-md py-5 px-5 rounded-md text-white hover:shadow-lg hover:opacity-80"
                      >
                        Send
                      </button>
                    )}
                    {this.state.isLoading && (
                      <button
                        type="button"
                        className="w-full mt-2 btn bg-primary py-5 px-5 rounded-md text-white shadow-lg opacity-80 flex items-center justify-center cursor-default"
                      >
                        <BiLoaderAlt className="animate-spin h-5 w-5 mr-2" /> Loading...
                      </button>
                    )}
                  </>
                )}

                <span className="text-red-500 text-sm italic mt-2 text-left block">{this.state.apiError}</span>
                <p className="mt-2 md:mt-4">
                  <NavLink to="/login" className="font-semibold">
                    Back to Login
                  </NavLink>
                </p>
                {/* <p className='mt-4'>Don’t have an account ? <NavLink to="/registration" 
                        className=' text-secondary hover:text-primary'>Register here</NavLink></p> */}
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
}

export default withParams(ResetPasswordForm);
