import React, { Component } from 'react';
import Form from './../components/EditProfileForm';
import Breadcrumb from './../components/Breadcrumb';

class EditProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active: true,
      isLoading: false,
      isError: false,
      data: [],
    };
  }

  render() {
    return (
      <>
        <Breadcrumb />
        <div className="flex  items-center">
          <h1 className="text-xl md:text-3xl mb-4 font-bold italic">Edit Profile</h1>
        </div>
        <Form />
      </>
    );
  }
}

export default EditProfile;
