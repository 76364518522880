const settings = {};

const api = {
  adminLogin: '/checklogin',
  resetPassword: '/api/reset-password',
  newPassword: '/api/new-password',
  verifyOtp: '/api/direct/su/verify-otp',
  bulkUpload: process.env.REACT_APP_DIRECT_URL + '/su/bulk-upload',
  templateDownload: process.env.REACT_APP_DIRECT_URL + '/su/template-download',
  schoolEdit: process.env.REACT_APP_DIRECT_URL + '/su/edit-school',
  suAdminEdit: process.env.REACT_APP_DIRECT_URL + '/su/edit-admin',
  susEdit: process.env.REACT_APP_DIRECT_URL + '/su/edit-sus',
  studentEdit: process.env.REACT_APP_DIRECT_URL + '/su/edit-student',
  schoolRegister: process.env.REACT_APP_DIRECT_URL + '/su/register-school',
  susRegister: process.env.REACT_APP_DIRECT_URL + '/su/register-sus',
  parentRegister: process.env.REACT_APP_DIRECT_URL + '/su/register-parent',
  schoolDelete: process.env.REACT_APP_DIRECT_URL + '/su/delete-school',
  susDelete: process.env.REACT_APP_DIRECT_URL + '/su/delete-sus',
  parentDelete: process.env.REACT_APP_DIRECT_URL + '/su/delete-parent',
  schoolStatusChange: process.env.REACT_APP_DIRECT_URL + '/su/status-change',
  coachStatusChange: process.env.REACT_APP_DIRECT_URL + '/su/coach-status-change',
  parentStatusChange: process.env.REACT_APP_DIRECT_URL + '/su/parent-status-change',
  suAdminStatusChange: process.env.REACT_APP_DIRECT_URL + '/su/suadmin-status-change',
  testStatusChange: process.env.REACT_APP_DIRECT_URL + '/su/test-status-change',
  suAdminDelete: process.env.REACT_APP_DIRECT_URL + '/su/suadmin-delete',
  suAdminAdd: process.env.REACT_APP_DIRECT_URL + '/su/suadmin-add',
  testAdd: process.env.REACT_APP_DIRECT_URL + '/su/test-add',
  testEdit: process.env.REACT_APP_DIRECT_URL + '/su/edit-test',
  testDelete: process.env.REACT_APP_DIRECT_URL + '/su/test-delete',
  //testExport:  '/api/v1/download-batch-test',
  testExport: process.env.REACT_APP_DIRECT_URL + '/su/download-batch-test',
  studentAdd: process.env.REACT_APP_DIRECT_URL + '/su/student-add',
  studentDelete: process.env.REACT_APP_DIRECT_URL + '/su/student-delete',
  profileEdit: process.env.REACT_APP_DIRECT_URL + '/su/profile-edit',
  countriesUpdate: process.env.REACT_APP_DIRECT_URL + '/su/countries-update',
  studentVerfiy: process.env.REACT_APP_DIRECT_URL + '/su/verify-child',
  updateBatch: process.env.REACT_APP_DIRECT_URL + '/su/update-batch',
  getHome: process.env.REACT_APP_API_URL + '/CommHome.json',
  getWelcome: process.env.REACT_APP_API_URL + '/CommWelcome.json',
  getSchool: process.env.REACT_APP_API_URL + '/CommSchool.json',
  getSchools: process.env.REACT_APP_API_URL + '/CommSchools.json',
  getCoachs: process.env.REACT_APP_API_URL + '/CommCoachs.json',
  getCoach: process.env.REACT_APP_API_URL + '/CommCoach.json',
  getParents: process.env.REACT_APP_API_URL + '/CommParents.json',
  getParent: process.env.REACT_APP_API_URL + '/CommParent.json',
  getTests: process.env.REACT_APP_API_URL + '/CommTests.json',
  getTest: process.env.REACT_APP_API_URL + '/CommTest.json',
  getProfile: process.env.REACT_APP_API_URL + '/CommProfile.json',
  getProfileFields: process.env.REACT_APP_API_URL + '/CommProfileFields.json',
  getStudents: process.env.REACT_APP_API_URL + '/CommStudents.json',
  getStudent: process.env.REACT_APP_API_URL + '/CommStudent.json',
  getSuAdmins: process.env.REACT_APP_API_URL + '/CommSuAdmins.json',
  getSuAdmin: process.env.REACT_APP_API_URL + '/CommSuAdmin.json',
  getCountries: process.env.REACT_APP_API_URL + '/CommCountries.json',
  getBatches: process.env.REACT_APP_API_URL + '/CommBatches.json',
  getBatchTests: process.env.REACT_APP_API_URL + '/CommBatchTests.json',
};

export { api, settings };
