import React, { Component } from 'react';
import Welcome from './../components/Welcome';
import { titleUpdate } from './helpers';

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active: true,
      isLoading: false,
      isError: false,
      componentData: [],
    };
  }

  componentDidMount() {
    const that = this;
    // that.setState({ isLoading: true, isError: false })
    /*  axios.get(api.getHome).then(response => {
       this.setState({
         componentData: response.data.components
       })
       this.setState({ isLoading: false });
     })
     .catch(function (error) {
       that.setState({ isLoading: false, isError: true  })
     }) */
  }

  render() {
    const { componentData, isLoading, isError } = this.state;
    return (
      <>
        {titleUpdate('navigation.dashboard')}
        <Welcome />
      </>
    );
  }
}

export default Dashboard;
