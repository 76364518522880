import React, { Component } from 'react';
import Field from './Field';
import axios from 'axios';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getCountries } from '../services/getCountries';
import { api } from '../Settings';
import Loader from './../components/Loader';
import { BiLoaderAlt } from 'react-icons/bi';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getSchool } from '../services/getSchool';

const userData = JSON.parse(localStorage.getItem('_userData'));
const USER_TYPE = userData && userData.data.user_type;

function withParams(Component) {
  return (props) => <Component {...props} navigation={useNavigate()} params={useParams()} />;
}

class SchoolEditForm extends Component {
  state = {
    fields: [
      {
        name: 'user_type',
        type: 'hidden',
        value: USER_TYPE,
        validation: {
          required: true,
        },
        static: true,
        valid: true,
      },
      {
        name: 'school_name',
        label: 'School Name',
        type: 'text',
        value: '',
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
      },
      {
        name: 'auth_primary_person_name',
        label: 'Authorized Person Name',
        type: 'text',
        value: '',
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
      },
      {
        name: 'auth_primary_person_email',
        label: 'Authorized Person Email ',
        type: 'email',
        value: '',
        validation: {
          required: true,
          pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
        },
        valid: false,
        touched: false,
      },
      {
        name: 'auth_primary_person_contact',
        label: 'Authorized Person Contact Number',
        type: 'tel',
        value: '',
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
      },
      {
        name: 'auth_secondary_person_name',
        label: 'Authorized Secondary Person Name',
        type: 'text',
        value: '',
        validation: {
          required: false,
        },
        valid: true,
        touched: false,
      },
      {
        name: 'auth_secondary_person_email',
        label: 'Authorized Secondary Person Email',
        type: 'email',
        value: '',
        validation: {
          required: false,
          pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
        },
        valid: true,
        touched: false,
      },
      {
        name: 'auth_secondary_person_contact',
        label: 'Authorized Secondary Person Contact',
        type: 'tel',
        value: '',
        validation: {
          required: false,
        },
        valid: true,
        touched: false,
      },
      {
        name: 'students_count',
        label: 'Students Count',
        type: 'text',
        value: '',
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
      },
      {
        name: 'principle_name',
        label: 'Principal Name',
        type: 'text',
        value: '',
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
      },
      {
        name: 'principle_email',
        label: 'Principal Email',
        type: 'email',
        value: '',
        validation: {
          required: true,
          pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
        },
        valid: false,
        touched: false,
      },
      {
        name: 'email_for_the_spunleash_report_to_be_sent_to',
        label: 'Email for the Sportunleash Report to be Sent to',
        type: 'email',
        value: '',
        validation: {
          required: true,
          pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
        },
        valid: false,
        touched: false,
      },
      {
        name: 'school_type',
        label: 'School Type',
        type: 'select',
        placeholder: 'Select a type',
        value: '',
        options: [
          {
            id: 'government',
            name: 'Government',
          },
          {
            id: 'public',
            name: 'Public',
          },
          {
            id: 'private',
            name: 'Private',
          },
          {
            id: 'state',
            name: 'State',
          },
        ],
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
      },
      {
        name: 'contact_number',
        label: 'Contact Number',
        type: 'tel',
        value: '',
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
      },
      {
        name: 'email',
        label: 'School Email (Used to Create SU Account)*',
        type: 'email',
        value: '',
        validation: {
          required: true,
          pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
        },
        valid: false,
        touched: false,
      },

      {
        name: 'address',
        label: 'Address',
        type: 'text',
        value: '',
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
      },

      {
        name: 'city',
        label: 'City',
        type: 'text',
        value: '',
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
      },
      {
        name: 'province',
        label: 'Province',
        type: 'text',
        value: '',
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
      },
      {
        name: 'district',
        label: 'District',
        type: 'text',
        value: '',
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
      },
      {
        name: 'postal_code',
        label: 'Postal Code',
        type: 'text',
        value: '',
        validation: {
          required: false,
        },
        valid: true,
        touched: false,
      },
      {
        name: 'country',
        label: 'Country',
        type: 'country',
        value: '',
        validation: {
          required: true,
        },
        touched: false,
      },
      {
        name: 'school_website',
        label: 'Website',
        type: 'text',
        value: '',
        validation: {
          required: false,
        },
        valid: true,
        touched: false,
      },
    ],
    settings: null,
    isSubmitting: false,
    isLoading: false,
    formValid: false,
    invalidFields: false,
    successMessage: '',
    apiError: '',
    token: null,
    countries: [],
    editEnable: false,
  };

  constructor(props) {
    super(props);
    const { id } = props.params;

    this.state.id = id;
    this.getData(id);
  }

  async getData(id) {
    const data = await getSchool(id);
    const countryList = await getCountries();

    this.setState({ data: data });
    // data['province'] = "progg"

    this.state.fields.map((field) => {
      if (field.name === 'country') {
        field.options = countryList;
      }

      if (data[field.name]) {
        field.value = data[field.name];
        field.valid = true;
      }

      if (!field.valid) {
        this.setState({ invalidFields: true });
      }
    });

    setTimeout(() => {
      if (!this.state.invalidFields) {
        this.setState({ formValid: true });
      }
    }, 100);
  }

  fieldChange = (event, field, index) => {
    const updatedField = { ...field };
    updatedField.value = event && event.target.value;

    if (field.type === 'country' || field.type === 'tel') {
      updatedField.value = field.value;
    }

    updatedField.valid = this.checkValidity(updatedField);

    const updatedFields = [...this.state.fields];
    updatedFields.splice(index, 1, updatedField);
    let formValid = true;
    for (let field of updatedFields) {
      if (!field.valid) {
        formValid = false;
      }
    }
    this.setState({
      fields: updatedFields,
      formValid: formValid,
      editEnable: true,
    });

    console.log(field);
  };

  checkValidity = (field) => {
    const rules = field.validation;
    const value = field.value;
    let isValid = true;

    //console.log(field.name);

    if (!rules) {
      return true;
    }
    if (rules.required) {
      isValid = value?.toString().trim() !== '' && isValid;
    }
    if (rules.minLength) {
      isValid = value.length >= rules.minLength && isValid;
    }
    if (rules.maxLength) {
      isValid = value.length <= rules.maxLength && isValid;
    }
    if (rules.pattern) {
      isValid = rules.pattern.test(value) && isValid;
    }
    if (field.name === 'password_confirmation') {
      const pwdItem = this.state.fields.filter((field, index) => field.name === 'password');
      let currPwd = pwdItem[0].value;

      //console.log(field)
      isValid = value === currPwd && isValid;
    }

    /* if (field.name === 'password') {
          
            const pwdItem = this.state.fields.filter((field, index) => field.name === 'password_confirmation');

            console.log(pwdItem[0]);
            this.checkValidity(pwdItem[0])
        } */

    return isValid;
  };
  fieldBlur(event, field, index) {
    if (field.touched) {
      return;
    }
    const updatedField = { ...field };
    updatedField.touched = true;
    updatedField.valid = this.checkValidity(updatedField);
    const updatedFields = [...this.state.fields];
    updatedFields.splice(index, 1, updatedField);
    this.setState({
      fields: updatedFields,
    });
  }
  onSubmit = (event) => {
    console.log(this.state.fields);

    if (this.state.formValid) {
      this.sendData();
    } else {
      this.state.fields.map((field, index) => {
        this.checkValidity(field);
        field.touched = true;
      });

      this.setState({ fields: this.state.fields });
    }
    event.preventDefault();
  };

  clearForm = () => {
    this.state.fields.map((field, index) => {
      if (!field.static) {
        field.value = '';
      }
    });
  };

  sendData = async () => {
    const dataObj = {};
    const { navigation } = this.props;

    dataObj.school_id = this.state.id;

    this.state.fields.map((field, index) => {
      if (field.name && field.value) {
        dataObj[field.name] = field.value;
      }
    });

    let that = this;
    that.setState({ isSubmitting: true });

    axios
      .post(api.schoolEdit, dataObj)
      .then(function (response) {
        let data = response.data;
        if (data.success) {
          that.setState({ successMessage: data.message });
          that.clearForm();

          toast.success(data.message, {
            autoClose: 3000,
            onClose: () => navigation('/school-management'),
          });
        } else {
          that.setState({ apiError: data.message });
        }

        that.setState({ isSubmitting: false });
      })
      .catch(function (error) {
        if (error.response.status == 422) {
          toast.error('Invalid school details', {
            autoClose: 3000,
          });
        } else {
          toast.error('School edit failed', {
            autoClose: 3000,
          });
        }

        that.setState({ isSubmitting: false, apiError: 'Invalid school details' });
        console.log(error);
        //that.setState({  successMessage: 'Congratulations, your account has been successfully created.' });
        /*  toast.success( 'Congratulations, your account has been successfully created.', {
                autoClose: 3000,
                onClose: () =>  navigation("/school-management")
            }); */
      });
  };

  render() {
    const { isLoading, settings } = this.state;
    return (
      <>
        <div className="mx-auto mt-4">
          <div className="w-full">
            <form onSubmit={(event) => this.onSubmit(event)}>
              <div className="md:grid md:grid-cols-2 gap-x-8 gap-y-4">
                {this.state.fields.map((field, index) => {
                  return (
                    <Field
                      key={index}
                      fieldConfig={field}
                      blured={(event) => this.fieldBlur(event, field, index)}
                      changed={(event) => this.fieldChange(event, field, index)}
                    />
                  );
                })}
              </div>

              <div className="">
                {isLoading ? <Loader /> : <></>}

                <>
                  {!this.state.isSubmitting && (
                    <div className="flex justify-end items-center mt-8 mb-8 gap-x-4">
                      {this.state.successMessage ? (
                        <span className="text-success text-base italic">{this.state.successMessage} </span>
                      ) : (
                        this.state.apiError && (
                          <span className="text-error text-base italic">{this.state.apiError} </span>
                        )
                      )}

                      <button
                        type="submit"
                        className={`inline-block btn bg-primary shadow-md py-3 px-5 rounded-md text-white hover:shadow-lg hover:opacity-80 ${
                          isLoading || (!this.state.editEnable && 'opacity-50 pointer-events-none')
                        }`}
                        disabled={!this.state.editEnable}
                      >
                        Submit
                      </button>
                      <Link
                        to={'/school-management'}
                        className={`inline-block btn border border-primary bg-transparent shadow-md py-3 px-5 rounded-md text-primary hover:shadow-lg hover:opacity-80 ${
                          isLoading && 'opacity-50 pointer-events-none'
                        }`}
                        data-disabled={!this.state.formValid}
                      >
                        Cancel
                      </Link>
                    </div>
                  )}
                  {this.state.isSubmitting && (
                    <div className="flex justify-end items-start mt-8 gap-x-4">
                      <button
                        type="button"
                        className="btn bg-primary py-3 px-5 rounded-md text-white shadow-lg opacity-80 flex items-center justify-center cursor-default"
                      >
                        <BiLoaderAlt className="animate-spin h-5 w-5 mr-2" /> Submitting...
                      </button>
                    </div>
                  )}
                </>
              </div>
            </form>
          </div>
        </div>
        <ToastContainer />
      </>
    );
  }
}

export default withParams(SchoolEditForm);
