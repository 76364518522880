import { useEffect } from 'react';
import { CurrentTitle } from '../../atoms';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';

export const TitleComp = () => {
  const { t, i18n } = useTranslation();
  const [titleState, setTitleState] = useRecoilState(CurrentTitle);

  useEffect(() => {
    document.title = `${t('general.sportunleash')} - ${t(titleState)}`;
  }, [titleState]);
};
