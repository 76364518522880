import React, { Fragment, useEffect, useRef, useState } from 'react';

import { BrowserRouter as Router, Routes, Route, Navigate, Outlet } from 'react-router-dom';

import axios from 'axios';
import { Suspense } from 'react';
import Loader from './components/Loader';

/* IMPORT COMPORNENTS */
import Login from './pages/Login';
import Logout from './pages/Logout';
import ForgetPassword from './pages/ForgetPassword';
import NewPassword from './pages/NewPassword';
import ResetPassword from './pages/ResetPassword';
import Dashboard from './pages/Dashboard';
import { useTranslation } from 'react-i18next';
import { getComponentData } from './pages/helpers';

import { Test, SUS, School, Su, Student } from './pages';
import { BaseComp } from './pages/baseComp';

import ScrollToTop from './ScrollToTop';
import MyProfile from './pages/MyProfile';
import EditProfile from './pages/EditProfile';
import Settings from './pages/Settings';
import ManageCountries from './pages/ManageCountries';

let user = false;

/* END OF IMPORT COMPORNENTS */
const [ASSCESS_TOKEN, REFRESH_TOKEN, USER_KEY, USER_TYPE] = getComponentData();
console.debug([ASSCESS_TOKEN, REFRESH_TOKEN, USER_KEY, USER_TYPE], 'gggggggggggggg');
// const userData = JSON.parse(localStorage.getItem('_userData'));
// const ASSCESS_TOKEN = userData && userData.data.token.access_token;
// const REFRESH_TOKEN = userData && userData.data.token.refresh_token;
// const USER_KEY = userData && userData.data.user_key;
// const USER_TYPE = userData && userData.data.user_type;

if (ASSCESS_TOKEN) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${ASSCESS_TOKEN}`;
  axios.defaults.headers.common['User-Key'] = `${USER_KEY}`;
  axios.defaults.headers.common['User-Rt'] = `${REFRESH_TOKEN}`;
  // document.cookie = `at=${ASSCESS_TOKEN}`;
  user = true;
}

const ProtectedRoute = ({ user, redirectPath = '/login' }) => {
  /*  const userData =  JSON.parse(localStorage.getItem("_userData"));
   const ASSCESS_TOKEN = (userData) && userData.data.data.access_token; */

  if (!ASSCESS_TOKEN) {
    return <Navigate to={redirectPath} replace />;
  }

  return <Outlet />;
};

const ProtectedRouteByType = ({ userTypes, redirectPath = '/' }) => {
  if (!userTypes.includes(USER_TYPE?.toUpperCase() || 'ADMIN')) {
    return <Navigate to={redirectPath} replace />;
  }

  return <BaseComp />;
};

function App() {
  const { t, i18n } = useTranslation();

  // const googleTranslateElementInit = () => {
  //   new window.google.translate.TranslateElement(
  //     {
  //       pageLanguage: 'en', includedLanguages: 'en,nl,es,zh-CN,sp,hi,ru', autoDisplay: false,
  //     },
  //     "google_translate_element"
  //   );
  // };

  // // spanish
  // // Hindi
  // // Chinese
  // // russian
  // // english
  // // duch

  // useEffect(() => {
  //   var addScript = document.createElement("script");
  //   addScript.setAttribute(
  //     "src",
  //     "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
  //   );
  //   document.body.appendChild(addScript);
  //   window.googleTranslateElementInit = googleTranslateElementInit;
  // }, []);

  return (
    <>
      {/* <Student /> */}
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/login" element={<Login />} extend />
          <Route path="/forget-password" element={<ForgetPassword />} extend />
          <Route path="/new-password" element={<NewPassword />} extend />
          <Route path="/reset-password" element={<ResetPassword />} extend />

          <Route element={<ProtectedRoute user={user} />}>
            <Route path="/" element={<BaseComp />}>
              <Route path="/" element={<Dashboard />} />
              </Route>

              <Route element={<ProtectedRouteByType userTypes={['ADMIN']} />}>
                <Route path="/su-admin/" element={<Su.SuAdmin />} />
                <Route path="/su-admin/create" element={<Su.SuAdminCreate />} />
                <Route path="/su-admin/:id" element={<Su.SuAdminDetail />} />
                <Route path="/su-admin/:id/edit" element={<Su.SuAdminEdit />} />
              </Route>

              <Route path="/school-management" element={<BaseComp />}>
              <Route path="/school-management/" element={<School.SchoolManagement />} />
              <Route path="/school-management/create" element={<School.SchoolCreate />} />
              <Route path="/school-management/:id" element={<School.SchoolDetail />} />
              <Route path="/school-management/:id/edit" element={<School.SchoolEdit />} />
               </Route>

            <Route path="/sus-management" element={<BaseComp />}>
              <Route path="/sus-management" element={<SUS.SUSManagement />} />
              <Route path="/sus-management/create" element={<SUS.SUSCreate />} />
              <Route path="/sus-management/:id/edit" element={<SUS.SUSEdit />} />
              <Route path="/sus-management/:id" element={<SUS.SUSDetail />} />
            </Route>

            <Route element={<ProtectedRouteByType userTypes={['ADMIN']} />}>
              <Route path="/test-management" element={<Test.TestManagement />} />
              <Route path="/test-management/create" element={<Test.TestCreate />} />
              <Route path="/test-management/:id" element={<Test.TestDetail />} />
              <Route path="/test-management/:id/edit" element={<Test.TestEdit />} />
            </Route>

            <Route path="/student-management" element={<BaseComp />}>
              <Route path="/student-management/" exact element={<Student.StudentManagement />} />
              <Route path="/student-management/create" element={<Student.StudentCreate />} />
              <Route path="/student-management/:id" element={<Student.StudentDetail />} />
              <Route path="/student-management/:id/edit" element={<Student.StudentEdit />} />
            </Route>

            <Route path="/my-profile" element={<BaseComp />}>
              <Route path="/my-profile/" element={<MyProfile />} />
              <Route path="/my-profile/edit" element={<EditProfile />} />
            </Route>

            <Route path="/settings" element={<Settings />} />
            <Route path="/settings/countries" element={<BaseComp />}>
              <Route path="/settings/countries/" element={<ManageCountries />} />
            </Route>
            <Route path="/logout" element={<Logout />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default function WrappedApp() {
  return (
    <Suspense
      fallback={
        <span className=" top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <Loader />
        </span>
      }
    >
      <App className="z-30" />
    </Suspense>
  );
}
