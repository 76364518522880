import { FiChevronRight } from 'react-icons/fi';
import { Link, useLocation } from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';

function Breadcrumbs() {
  const location = useLocation();

  const routes = [
    { path: '/', breadcrumb: 'Dashboard' },
    { path: '/su-admin', breadcrumb: 'Country Admins' },
    {
      path: '/su-admin/:id',
      breadcrumb: location.state?.first_name
        ? location.state?.first_name + ' ' + location.state?.last_name
        : 'Admin Details',
    },
    { path: '/su-admin/create', breadcrumb: 'Create' },

    { path: '/school-management', breadcrumb: 'Schools' },
    { path: '/school-management/:id', breadcrumb: location.state?.school_name },
    { path: '/school-management/:id/edit', breadcrumb: location.state?.school_name },
    { path: '/school-management/create', breadcrumb: 'Add New School' },

    { path: '/sus-management', breadcrumb: 'SportUnleash Specialist' },
    {
      path: '/sus-management/:id',
      breadcrumb: location.state?.first_name
        ? location.state?.first_name + ' ' + location.state?.last_name
        : 'SUS Details',
    },
    { path: '/sus-management/create', breadcrumb: 'Add a Specialist' },

    { path: '/parent-management', breadcrumb: 'Parents' },
    { path: '/parent-management/:id', breadcrumb: location.state?.fathers_name },
    { path: '/parent-management/create', breadcrumb: 'Create a Parent' },

    { path: '/test-management', breadcrumb: 'Tests' },
    { path: '/test-management/:id', breadcrumb: 'Test Details' },
    { path: '/test-management/create', breadcrumb: 'Add a Test' },

    { path: '/student-management', breadcrumb: 'Students' },
    {
      path: '/student-management/:id',
      breadcrumb: location.state?.first_name
        ? location.state?.first_name + ' ' + location.state?.last_name
        : 'Student Details',
    },
    { path: '/student-management/create', breadcrumb: 'Add a Student' },
  ];

  const breadcrumbs = useBreadcrumbs(routes);

  return (
    <nav className="flex flex-wrap items-start mb-4 text-sm md:text-base">
      {breadcrumbs.map(({ match, breadcrumb }) => (
        <Link
          key={breadcrumb.key}
          to={breadcrumb.key}
          className={`flex items-start ${
            match.pathname === location.pathname ? 'text-body' : 'text-body/50 hover:text-primary'
          }`}
        >
          {breadcrumb}
          {match.pathname !== location.pathname && (
            <span className="px-1 md:mt-0.5 text-2xl text-body/20">
              <FiChevronRight />
            </span>
          )}
        </Link>
      ))}
    </nav>
  );
}

export default Breadcrumbs;
