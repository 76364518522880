import { CurrentTitle } from '../atoms';
import { useRecoilState } from 'recoil';
import i18n from 'i18next';
import { useEffect } from 'react';

/**
 *
 * @function UpdatePathState
 * @description this is the hook. this is also callable using functional components
 */
const UpdatePathState = async (incommingLangLocation) => {
  const enTranslations = i18n.getResource('en', 'translation');
  var res = incommingLangLocation.split('.').reduce(function (o, k) {
    return o && o[k];
  }, enTranslations);
};

/**
 *
 * @Description this is a way to call react hooks using class components
 * @param {title}
 */

const StateChangeComp = ({ title }) => {
  const [titleSt, setTitleSt] = useRecoilState(CurrentTitle);

  useEffect(() => {
    if (title) {
      setTitleSt(title);
    }
  }, [titleSt]);
};

export { StateChangeComp, UpdatePathState };
