import React, { Component } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { FaSlidersH, FaRegHandshake } from 'react-icons/fa';
import { MdOutlineExplore, MdPeople } from 'react-icons/md';
import { BiImages, BiLinkExternal, BiLoaderAlt } from 'react-icons/bi';
import { GiWorld } from 'react-icons/gi';
import { AiOutlineMenu, AiOutlineHome } from 'react-icons/ai';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { api } from '../Settings';
import Drawer from './Drawer';

const navigation = [
  {
    label: 'Home',
    url: '/',
    icon: <AiOutlineHome />,
    target: '_self',
  },
  {
    url: '/explore-dilmah',
    label: 'Explore Dilmah',
    icon: <MdOutlineExplore />,
    target: '_self',
  },
  {
    url: '/partners',
    label: 'Partners',
    icon: <FaRegHandshake />,
    target: '_self',
  },
  {
    // url: "/digital-assets",
    url: '/collections',
    label: 'Digital Assets',
    icon: <BiImages />,
    target: '_self',
  },
  {
    url: '/dilmah-universe',
    // url: "https://www.dilmahtea.com/dilmah-online",
    label: 'Dilmah Universe',
    type: 'extranal',
    icon: <GiWorld />,
    //  target:"_blank"
  },
  /*  {
      url: "/people",
      label: "People",
      icon: <MdPeople />,
      target:"_self" 
  }, */
];

function withParams(Component) {
  return (props) => <Component {...props} navigation={useNavigate()} location={useLocation()} />;
}

let timer;
class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active: true,
      isLoading: false,
      isOpen: false,
      searchparams: '',
      searchResults: [],
    };

    this.toggleDrawer = this.toggleDrawer.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleItemClick = this.handleItemClick.bind(this);
    this.getResults = this.getResults.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const { state } = nextProps.location;
    const { val } = state || {};

    this.setState({ searchparams: val });
  }

  toggleDrawer() {
    const currentState = this.state.active;
    this.setState({ active: !currentState });
  }

  handleCallback = (childData) => {
    //console.log(childData);
    this.setState({ active: childData.show });
  };

  handleChange(event) {
    const target = event.target;
    const val = target.value ? target.value : '';
    const name = target.name;
    this.setState({ [name]: val });

    clearTimeout(timer);

    if (val) {
      timer = setTimeout(() => {
        this.getResults(val);
      }, 250);
    } else {
      this.setState({ isOpen: false });
    }
  }

  handleKeyDown(event) {
    const { navigation } = this.props;
    if (event.key === 'Enter') {
      const target = event.target;
      const val = target.value ? target.value : '';
      const name = target.name;

      navigation('/digital-assets', { state: { val } });
      this.setState({ [name]: val });
    }
  }

  handleItemClick(event) {
    const { navigation } = this.props;
    const val = event.currentTarget.getAttribute('data-value');
    navigation('/digital-assets', { state: { val } });
    this.setState({ isOpen: false });
  }

  getResults(q) {
    this.setState({ searchResults: [], isLoading: true, isOpen: true });

    axios.get(api.getSearchSuggestions, { params: { searchPhrase: q } }).then((response) => {
      this.setState({ searchResults: response.data.items });
      this.setState({ isLoading: false });
    });
  }

  render() {
    return (
      <>
        <a
          onClick={this.toggleDrawer}
          className="side-menu-trigger text-primary text-2xl cursor-pointer fixed top-4 left-4"
        >
          {' '}
          <AiOutlineMenu />
        </a>
        <Drawer show={this.state.active} drawerCallback={this.handleCallback} />
        {this.state.active && (
          <div
            onClick={this.toggleDrawer}
            className="bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-30"
          ></div>
        )}
      </>
    );
  }
}

export default withParams(Header);
